
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Cell, Row, Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";
import AccountNumberPropertyPlugin from "../../components/receipts/components/plugins/AccountNumberPropertyPlugin";
import AmountPropertyPlugin from "../../components/receipts/components/plugins/AmountPropertyPlugin";
import ApprovalCodePropertyPlugin from "../../components/receipts/components/plugins/ApprovalCodePropertyPlugin";
import BillingZipPropertyPlugin from "../../components/receipts/components/plugins/BillingZipPropertyPlugin";
import ButtonPlugin from "../../components/receipts/components/plugins/ButtonPlugin";
import CardLogoPropertyPlugin from "../../components/receipts/components/plugins/CardLogoPropertyPlugin";
import CheckingAccountNumberPropertyPlugin from "../../components/receipts/components/plugins/CheckingAccountNumberPropertyPlugin";
import ClientNamePropertyPlugin from "../../components/receipts/components/plugins/ClientNamePropertyPlugin";
import ConvenienceFeePropertyPlugin from "../../components/receipts/components/plugins/ConvenienceFeePropertyPlugin";
import CryptogramPropertyPlugin from "../../components/receipts/components/plugins/CryptogramPropertyPlugin";
import CustomerNamePropertyPlugin from "../../components/receipts/components/plugins/CustomerNamePropertyPlugin";
import LinkPropertyPlugin from '../../components/receipts/components/plugins/LinkPropertyPlugin';
import ItemReferencePropertyPlugin from '../../components/receipts/components/plugins/ItemReferencePropertyPlugin';
import DepartmentAddressPropertyPlugin from "../../components/receipts/components/plugins/DepartmentAddressPropertyPlugin";
import DepartmentNamePropertyPlugin from "../../components/receipts/components/plugins/DepartmentNamePropertyPlugin";
import DisclaimerPropertyPlugin from "../../components/receipts/components/plugins/DisclaimerPropertyPlugin";
import EmailPropertyPlugin from "../../components/receipts/components/plugins/EmailPropertyPlugin";
import EntryPropertyPlugin from "../../components/receipts/components/plugins/EntryPropertyPlugin";
import MerchantIdPropertyPlugin from "../../components/receipts/components/plugins/MerchantIdPropertyPlugin";
import NameOnCardPropertyPlugin from "../../components/receipts/components/plugins/NameOnCardPropertyPlugin";
import OrderCapturedByEmployeePropertyPlugin from "../../components/receipts/components/plugins/OrderCapturedByEmployeePropertyPlugin";
import OrderLinesPropertyPlugin from "../../components/receipts/components/plugins/OrderLinesPropertyPlugin";
import ClientMetadataPropertyPlugin from "../../components/receipts/components/plugins/ClientMetadataPropertyPlugin";
import PaymentChannelNamePropertyPlugin from "../../components/receipts/components/plugins/PaymentChannelNamePropertyPlugin";
import PaymentTypePropertyPlugin from "../../components/receipts/components/plugins/PaymentTypePropertyPlugin";
import PhoneNumberPropertyPlugin from "../../components/receipts/components/plugins/PhoneNumberPropertyPlugin";
import PinVerifiedPropertyPlugin from "../../components/receipts/components/plugins/PinVerifiedPropertyPlugin";
import PlainTextPlugin from "../../components/receipts/components/plugins/PlainTextPlugin";
import ReceiptContainerPlugin from "../../components/receipts/components/plugins/ReceiptContainerPlugin";
import ReferenceNumberPropertyPlugin from "../../components/receipts/components/plugins/ReferenceNumberPropertyPlugin";
import RemainingBalanceAmountPropertyPlugin from "../../components/receipts/components/plugins/RemainingBalanceAmountPropertyPlugin";
import ResponseCodePropertyPlugin from "../../components/receipts/components/plugins/ResponseCodePropertyPlugin";
import SignatureDisclaimerPropertyPlugin from "../../components/receipts/components/plugins/SignatureDisclaimerPropertyPlugin";
import SignaturePropertyPlugin from "../../components/receipts/components/plugins/SignaturePropertyPlugin";
import SpacerPlugin from "../../components/receipts/components/plugins/SpacerPlugin";
import TerminalIdentifierPropertyPlugin from "../../components/receipts/components/plugins/TerminalIdentifierPropertyPlugin";
import TotalAmountPropertyPlugin from "../../components/receipts/components/plugins/TotalAmountPropertyPlugin";
import TransactionDatePropertyPlugin from "../../components/receipts/components/plugins/TransactionDatePropertyPlugin";
import TransactionDateTimePropertyPlugin from "../../components/receipts/components/plugins/TransactionDateTimePropertyPlugin";
import TransactionIdPropertyPlugin from "../../components/receipts/components/plugins/TransactionIdPropertyPlugin";
import TransactionTimePropertyPlugin from "../../components/receipts/components/plugins/TransactionTimePropertyPlugin";
import TransactionTypePropertyPlugin from "../../components/receipts/components/plugins/TransactionTypePropertyPlugin";
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import { ProcessedOrder} from "../../models/Order";
import { ReceiptTypeEnum, Receipt as ReceiptModel, Client, Department, PaymentChannel } from "../../models/Client";
import TylerEagleRedirectPlugin from "../paymentChannels/web/components/plugins/TylerEagleRedirectPlugin";
import { Button, ButtonToolbar, Form, Modal } from "react-bootstrap";
import { EmailRequest, EmailType, TemplateType } from "../../models/EmailRequest";
import FormHeader from "../../components/layout/FormHeader";
import { sendEmailAction, SEND_EMAIL_FAILURE, SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS } from "../../redux/actions/emails";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import EscPosEncoder from 'esc-pos-encoder';
import moment from 'moment';
import _ from 'lodash';
import { RedirectRequest } from "../../models/RedirectIntegration";
import { updateItemStatus, updateStCharlesStatus } from "../../redux/actions/orderManagement";
import ReasonPropertyPlugin from '../../components/receipts/components/plugins/ReasonPropertyPlugin';

export interface IParams {
    id: string
}

export interface IReceiptPageProps {
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    content: Content,
    webApplication: WebApplication,
    processedOrder: ProcessedOrder,
    actionResult: IActionResult,
    emailIsSending: boolean,
    emailActionResult: IActionResult,
    isStCharles?: boolean,
    redirectRequest: RedirectRequest,
    isPike?: boolean,
    statusUpdate?: boolean,
}

const ReceiptPage = ({ webApplication, content, processedOrder, paymentChannel, client, department, actionResult, emailIsSending, emailActionResult, isStCharles, redirectRequest, isPike, statusUpdate }: IReceiptPageProps) => {
    let actionToken = "ReceiptPage"
    const dispatch = useDispatch();
    const { id }:IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [, setWebPage] = useState<WebPage>();
    const [, setRedirect] = useState<string>("");
    const [receipt, setReceipt] = useState<ReceiptModel>();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [validated, setValidated] = useState<boolean>(false);

    const setRoute = (path:string, webApplicationName:string) => {
        return path.replace(":id", webApplicationName) + "?" + WebPageEnum.Receipt;
    }

    let receiptContainerPlugin = ReceiptContainerPlugin as CellPlugin<unknown, unknown>;
    let accountNumberPropertyPlugin = AccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let amountPropertyPlugin = AmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let approvalCodePropertyPlugin = ApprovalCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let billingZipPropertyPlugin = BillingZipPropertyPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let cardLogoPropertyPlugin = CardLogoPropertyPlugin as CellPlugin<unknown, unknown>;
    let checkingAccountNumberPropertyPlugin = CheckingAccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let clientNamePlugin = ClientNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let clientMetadataPropertyPlugin =  ClientMetadataPropertyPlugin as CellPlugin<unknown, unknown>;
    let convenienceFeePropertyPlugin = ConvenienceFeePropertyPlugin as CellPlugin<unknown, unknown>;
    let cryptogramPropertyPlugin = CryptogramPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentAddressPropertyPlugin = DepartmentAddressPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentNamePropertyPlugin = DepartmentNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let disclaimerPropertyPlugin = DisclaimerPropertyPlugin as CellPlugin<unknown, unknown>;
    let emailPropertyPlugin = EmailPropertyPlugin as CellPlugin<unknown, unknown>;
    let entryPropertyPlugin = EntryPropertyPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let itemReferencePropertyPlugin = ItemReferencePropertyPlugin as CellPlugin<unknown, unknown>;
    let linkPropertyPlugin = LinkPropertyPlugin as CellPlugin<unknown, unknown>;
    let merchantIdPropertyPlugin = MerchantIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let nameOnCardPropertyPlugin = NameOnCardPropertyPlugin as CellPlugin<unknown, unknown>;
    let orderCapturedByEmployeePropertyPlugin = OrderCapturedByEmployeePropertyPlugin as CellPlugin<unknown, unknown>;
    let orderLinesPropertyPlugin = OrderLinesPropertyPlugin as CellPlugin<unknown, unknown>;
    let paymentChannelNamePropertyPlugin = PaymentChannelNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let paymentTypePropertyPlugin = PaymentTypePropertyPlugin as CellPlugin<unknown, unknown>;
    let phoneNumberPropertyPlugin = PhoneNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let pinVerifiedPropertyPlugin = PinVerifiedPropertyPlugin as CellPlugin<unknown, unknown>;
    let plainTextPropertyPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let receiptImagePlugin = image as CellPlugin<unknown, unknown>;
    let referenceNumberPropertyPlugin = ReferenceNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let remainingBalanceAmountPropertyPlugin = RemainingBalanceAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let responseCodePropertyPlugin = ResponseCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let signatureDisclaimerPropertyPlugin = SignatureDisclaimerPropertyPlugin as CellPlugin<unknown, unknown>;
    let signaturePropertyPlugin = SignaturePropertyPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let terminalIdentifierPropertyPlugin = TerminalIdentifierPropertyPlugin as CellPlugin<unknown, unknown>;
    let totalAmountPropertyPlugin = TotalAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDatePropertyPlugin = TransactionDatePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDateTimePropertyPlugin = TransactionDateTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionIdPropertyPlugin = TransactionIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTimePropertyPlugin = TransactionTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTypePropertyPlugin = TransactionTypePropertyPlugin as CellPlugin<unknown, unknown>;
    let customerNamePropertyPlugin = CustomerNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let tylerEagleRedirectPlugin = TylerEagleRedirectPlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let reasonPropertyPlugin = ReasonPropertyPlugin as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(receiptContainerPlugin);
    cellPlugins.push(accountNumberPropertyPlugin);
    cellPlugins.push(amountPropertyPlugin);
    cellPlugins.push(approvalCodePropertyPlugin);
    cellPlugins.push(billingZipPropertyPlugin);
    cellPlugins.push(buttonPlugin);
    cellPlugins.push(cardLogoPropertyPlugin);
    cellPlugins.push(checkingAccountNumberPropertyPlugin);
    cellPlugins.push(clientNamePlugin);
    cellPlugins.push(clientMetadataPropertyPlugin);
    cellPlugins.push(convenienceFeePropertyPlugin);
    cellPlugins.push(cryptogramPropertyPlugin);
    cellPlugins.push(customerNamePropertyPlugin);
    cellPlugins.push(departmentAddressPropertyPlugin);
    cellPlugins.push(departmentNamePropertyPlugin);
    cellPlugins.push(disclaimerPropertyPlugin);
    cellPlugins.push(emailPropertyPlugin);
    cellPlugins.push(entryPropertyPlugin);
    cellPlugins.push(horizontalRulePlugin);
    cellPlugins.push(linkPropertyPlugin);
    cellPlugins.push(itemReferencePropertyPlugin);
    cellPlugins.push(merchantIdPropertyPlugin);
    cellPlugins.push(nameOnCardPropertyPlugin);
    cellPlugins.push(orderCapturedByEmployeePropertyPlugin);
    cellPlugins.push(orderLinesPropertyPlugin);
    cellPlugins.push(paymentChannelNamePropertyPlugin);
    cellPlugins.push(paymentTypePropertyPlugin);
    cellPlugins.push(phoneNumberPropertyPlugin);
    cellPlugins.push(pinVerifiedPropertyPlugin);
    cellPlugins.push(plainTextPropertyPlugin);
    cellPlugins.push(receiptImagePlugin);
    cellPlugins.push(referenceNumberPropertyPlugin);
    cellPlugins.push(remainingBalanceAmountPropertyPlugin);
    cellPlugins.push(responseCodePropertyPlugin);
    cellPlugins.push(signatureDisclaimerPropertyPlugin);
    cellPlugins.push(signaturePropertyPlugin);
    cellPlugins.push(spacerPlugin);
    cellPlugins.push(terminalIdentifierPropertyPlugin);
    cellPlugins.push(totalAmountPropertyPlugin);
    cellPlugins.push(transactionDatePropertyPlugin);
    cellPlugins.push(transactionDateTimePropertyPlugin);
    cellPlugins.push(transactionIdPropertyPlugin);
    cellPlugins.push(transactionTimePropertyPlugin);
    cellPlugins.push(transactionTypePropertyPlugin);
    cellPlugins.push(tylerEagleRedirectPlugin);
    cellPlugins.push(imagePlugin);
    cellPlugins.push(reasonPropertyPlugin);

    var pluginPropertyMap = new Map<string, string>();
    pluginPropertyMap.set(accountNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.lastFourOnCard");
    pluginPropertyMap.set(amountPropertyPlugin.id, "processorData.receipt.amount");
    pluginPropertyMap.set(approvalCodePropertyPlugin.id, "processorData.receipt.approvalNumber");
    pluginPropertyMap.set(billingZipPropertyPlugin.id, "processorData.receipt.orderTransaction.zip");
    pluginPropertyMap.set(cardLogoPropertyPlugin.id, "processorData.receipt.cardLogo");
    pluginPropertyMap.set(clientNamePlugin.id, "clientName");
    pluginPropertyMap.set(clientMetadataPropertyPlugin.id, "processorData.receipt.orderTransaction.clientMetadata");
    pluginPropertyMap.set(convenienceFeePropertyPlugin.id, "processorData.receipt.convenienceFee");
    pluginPropertyMap.set(cryptogramPropertyPlugin.id, "processorData.receipt.cryptogram");
    pluginPropertyMap.set(customerNamePropertyPlugin.id, "processorData.receipt.nameOnCard");
    pluginPropertyMap.set(departmentAddressPropertyPlugin.id, "processorData.address");
    pluginPropertyMap.set(departmentNamePropertyPlugin.id, "departmentName");
    pluginPropertyMap.set(emailPropertyPlugin.id, "processorData.receipt.orderTransaction.email");
    pluginPropertyMap.set(entryPropertyPlugin.id, "processorData.receipt.entry");
    pluginPropertyMap.set(itemReferencePropertyPlugin.id, "processorData.receipt.accountNumbers");
    pluginPropertyMap.set(merchantIdPropertyPlugin.id, "processorData.receipt.merchantIdentifier");
    pluginPropertyMap.set(nameOnCardPropertyPlugin.id, "processorData.receipt.nameOnCard");
    pluginPropertyMap.set(orderCapturedByEmployeePropertyPlugin.id, "processorData.receipt.orderTransaction.initiatedBy");
    pluginPropertyMap.set(orderLinesPropertyPlugin.id, "processorData.receipt.orderTransaction.orderLines");
    pluginPropertyMap.set(paymentChannelNamePropertyPlugin.id, "paymentChannelName");
    pluginPropertyMap.set(paymentTypePropertyPlugin.id, "processorData.receipt.orderTransaction.paymentType");
    pluginPropertyMap.set(phoneNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.phone");
    pluginPropertyMap.set(pinVerifiedPropertyPlugin.id, "processorData.receipt.pinVerified");
    pluginPropertyMap.set(referenceNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.orderIdentifier");
    pluginPropertyMap.set(responseCodePropertyPlugin.id, "processorData.receipt.hostResponseCode");
    pluginPropertyMap.set(signaturePropertyPlugin.id, "processorData.receipt.signatureBase64");
    pluginPropertyMap.set(terminalIdentifierPropertyPlugin.id, "processorData.receipt.terminalIdentifier");
    pluginPropertyMap.set(totalAmountPropertyPlugin.id, "processorData.receipt.totalAmount");
    pluginPropertyMap.set(transactionDatePropertyPlugin.id, "processorData.receipt.createdAt");
    pluginPropertyMap.set(transactionIdPropertyPlugin.id, "processorData.receipt.transactionIdentifier");
    pluginPropertyMap.set(transactionTypePropertyPlugin.id, "processorData.receipt.orderTransaction.transactionType");
    pluginPropertyMap.set(transactionTypePropertyPlugin.id, "processorData.receipt.orderTransaction.transactionType");
    pluginPropertyMap.set(reasonPropertyPlugin.id, "processorData.receipt.orderTransaction.reason");

    useEffect(() => {
        if (processedOrder && processedOrder.processorData && processedOrder.processorData.receipt && client && department && paymentChannel) {
            var processorType = processedOrder.processorData.receipt.processorType;
            var paymentType = processedOrder.processorData.receipt.orderTransaction!.paymentType;
            var receiptType = ReceiptTypeEnum.CardPresent;

            if (paymentType === "ECheck") {
                receiptType = ReceiptTypeEnum.eCheck;
            } else if (processorType === "VantivExpress") {
                receiptType = ReceiptTypeEnum.CardNotPresent;
            } else if (processorType === "PayPal") {
                receiptType = ReceiptTypeEnum.CardNotPresent;
            }

            var _receipt = department.receipts!.filter(_ => _.receiptType === receiptType)[0];
            setReceipt(_receipt);

            var _processedOrder = processedOrder as any;
            _processedOrder.clientName = client.businessName;
            _processedOrder.departmentName = department.name;
            _processedOrder.paymentChannelName = paymentChannel.name;

            if (_receipt && (_receipt.contentId !== '00000000-0000-0000-0000-000000000000' || _receipt.contentId !== content.msbId)) {
                dispatch(getContentAction(_receipt.contentId, actionToken));
            } else {
            }
            if(isStCharles){
                const revObjIds = processedOrder?.processorData?.receipt?.orderTransaction?.orderLines?.map(lines => lines?.itemReferenceNumber)
                dispatch(updateStCharlesStatus(revObjIds))
            }
            if (isPike || statusUpdate) {
                const currentItemIds = processedOrder?.processorData?.receipt?.orderTransaction?.orderLines?.map((lines: any) => lines?.itemMetadata?.itemMsbid)
                const futureDelinQuents = processedOrder?.processorData?.receipt?.orderTransaction?.orderLines?.
                    map((lines: any) => lines?.itemMetadata?.futureDelinQuents)
                    .reduce((acc, arr) => acc.concat(arr), [])
                    .filter((value: any) => value !== undefined && value !== null);
                const itemIds = _.isEmpty(futureDelinQuents) ? currentItemIds : _.concat(currentItemIds, futureDelinQuents)
                const orderLines = processedOrder?.processorData?.receipt?.orderTransaction?.orderLines
                const orderTransactionId = processedOrder?.processorData?.receipt?.orderTransaction?.msbId
                dispatch(updateItemStatus(itemIds, orderLines, orderTransactionId))
            }
        }
    }, [processedOrder, client, department, paymentChannel, isStCharles, isPike, statusUpdate]);

    const populateCMSValueWithReceiptData = (value: Value) => {
        value.rows?.map(populateCMSValueReceiptDataRow);
    }

    const populateCMSValueReceiptDataRow = (row: Row, index: number, rows: Array<Row>) => {
        row.cells.map((cell: Cell, index: number) => {
            if (cell.plugin &&  cell.dataI18n && pluginPropertyMap.has(cell.plugin.id)) {
               var property = pluginPropertyMap.get(cell.plugin.id)!;
               var defaultValue = cell.dataI18n.default as any;

               if (defaultValue)
               {
                defaultValue.value = getNestedObjectValue(processedOrder, property);
               }
               else
               {
                 console.log("populateCMSValueReceiptDataRow");
                 console.log(property);
                 console.log(processedOrder);
               }
            }

            cell.rows?.map(populateCMSValueReceiptDataRow);
        });
    }

    const getNestedObjectValue = (data: any, field: string) => {
        const pList = field.split('.');
        let _data = data;

        pList.map((property: string, index: number) => {
            if (_data[property]) {
                _data = _data[property];
            }
        });
    
        return _data;
    }

    useEffect(() => {
        if (webApplication) {
            let webPage = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.Receipt)[0];
            if (webPage) { 
                if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                    dispatch(getContentAction(webPage.contentId, actionToken));
                } else {
                    //// TODO - need to handle, as there is content for page
                }
                setWebPage(webPage);
            }
        } else {
            ////redirect back to start and load the web application
            setRedirect(setRoute(Routes.WebApplicationStart.path, id));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webApplication]);

      /*
      useEffect(() => {
        if (content && webPage && content.msbId! === webPage.contentId) {
          console.log("useEffect content");
          console.log(content);
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    setValue(value);
                    break;
                }
            }
        }
      }, [content]);
    */

    const EmailModal = () => {
      if (processedOrder) {
        const orderReceipt = processedOrder.processorData?.receipt;
    
        return (
            <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
                <Modal.Header closeButton />

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleEmailReceipt}>
                        <FormHeader title="Email receipt" description="Please enter the email address below to receive a copy of the receipt." />
                        <Form.Group controlId="emailAddress">
                            <Form.Control required type="email" placeholder="Enter email address" defaultValue={orderReceipt?.orderTransaction?.email} />
                        </Form.Group>
                        <Form.Group className="form-footer">
                            <Button variant="outline-secondary" onClick={() => setShowEmailModal(false)}>Cancel</Button>
                            <Button type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
      } else {
        return(<></>);
      }
    }

    useEffect(() => {
      if (emailActionResult && emailActionResult.result) {
          if (emailActionResult.type === SEND_EMAIL_REQUEST && emailActionResult.token === actionToken) {
              if (emailActionResult.result === SEND_EMAIL_SUCCESS) {
                  setShowEmailModal(false);
                  dispatch(sendSuccessToastAction("Email successfully sent."));
              }  else if (emailActionResult.result === SEND_EMAIL_FAILURE) {
                  setShowEmailModal(false);
                  dispatch(sendErrorToastAction("Email failed to send."));
              }
          } 
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailActionResult]);

    const elementRef = useRef<any>();

    const getClassStyles = (element: any) => {
        const classNames = Array.from(element.getElementsByTagName('*')).map((el: any) => el.className).join(' ');
        const styleSheets = Array.from(document.styleSheets);
        let classStyles = '';
        styleSheets.forEach(sheet => {
            const cssRules = Array.from(sheet.cssRules);
            cssRules.forEach((rule: any) => {
                if (rule.selectorText && classNames.includes(rule.selectorText.split('.')[1])) {
                    classStyles += rule.cssText;
                }
            });
        });
        return classStyles;
    };

    const replaceRows = (html: any) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const rows = div.querySelectorAll('div.row');
        rows.forEach((row: Element) => {
            const colChildren = row.querySelectorAll('.col');
            if (colChildren.length === 2) {
                const content1 = colChildren[0].innerHTML;
                const content2 = colChildren[1].innerHTML;
                const newStructure = `
                    <div class="row" style="width: 100%;">
                        <table style="width: 100%; border-collapse: collapse;">
                            <tr>
                                <td style="width: 50%; text-align: left;">
                                    <div class="col">${content1}</div>
                                </td>
                                <td style="width: 50%; text-align: left;">
                                    <div class="col">${content2}</div>
                                </td>
                            </tr>
                        </table>
                    </div>`;
                const contentDiv = document.createElement('div');
                contentDiv.insertAdjacentHTML('beforeend', newStructure);
                row.innerHTML = contentDiv.innerHTML;
            }
        });
        return div.innerHTML;
    };

    const handleEmailReceipt = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        var _processedOrder = processedOrder as any;
        var processorType = processedOrder?.processorData?.receipt?.processorType;
        if (processorType === "PayPal") {
            _processedOrder.processorData.receipt.orderId = "";
            _processedOrder.processorData.receipt.transactionIdentifier = "";
            _processedOrder.processorData.receipt.orderTransaction.transactionIdentifier = "";
            _processedOrder.processorData.receipt.orderTransaction.orderSummary = {
                "clientId": client.msbId,
                "departmentId": _processedOrder.processorData.receipt.departmentId,
                "paymentChannelId": _processedOrder.processorData.receipt.paymentChannelId,
                "orderIdentifier": _processedOrder.processorData.receipt.orderTransaction.orderIdentifier,
                "externalCustomerReference": _processedOrder.processorData.receipt.orderTransaction.initiatedBy,
                "isImmutable": false,
                "convenienceFeeIsClientAbsorbed": false,
                "isVantivTransaction": false,
                "isDebitCardTransaction": false,
                "isMailOrTelephoneOrder": false,
                "internalCardType": "PayPal",
                "originalTransactionDate": _processedOrder.processorData.receipt.createdAt,
                "originalAmount": _processedOrder.processorData.receipt.orderTransaction.originalAmount,
                "remainingBalance": _processedOrder.processorData.receipt.orderTransaction.remainingBalance,
                "remainingAmount": _processedOrder.processorData.receipt.orderTransaction.remainingBalance,
                "orderTransactions": [],
                "id": 0,
                "msbId": "00000000-0000-0000-0000-000000000000",
                "createdAt": _processedOrder.processorData.receipt.createdAt
            }
        }

        if (form.checkValidity() !== false) {
            let emailRequest = new EmailRequest();
            emailRequest.emailType = EmailType.Receipt;
            emailRequest.templateType = TemplateType.StandardReceipt;
            emailRequest.recipientAddresses.push(form.elements.emailAddress.value);
            emailRequest.subject = "Order Receipt";
            emailRequest.model = _processedOrder;
            //emailRequest.renderedBody = `<!doctype html><head><meta name="viewport" content="width=device-width, initial-scale=1.0" /><style>${getClassStyles(elementRef.current)}</style></head><body>${replaceRows(elementRef.current.innerHTML)}</body></html>`;
            dispatch(sendEmailAction(emailRequest, actionToken));
            setValidated(true);
        }
    };

    useEffect(() => {
        if (content && receipt && content.msbId! === receipt.contentId) {
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    populateCMSValueWithReceiptData(value);
                    setValue(value);
                    break;
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    //==========================================================================================================================================
    // MSB Local Thermal Printer Handling...
    const [printParse, setPrintParse] = useState<[]>();

    useEffect(() => {
        let _printParse: any = [];
        const iterateValue = (obj: any) => {
            Object.keys(obj).forEach(key => {
                if (key === "dataI18n" && typeof obj[key] === 'object' && obj[key] !== null) _printParse.push(obj[key].default);
                if (typeof obj[key] === 'object' && obj[key] !== null) iterateValue(obj[key]);
            })
        }
        if (value && !printParse && navigator.usb) {
            iterateValue(value);
            setPrintParse(_printParse);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const delay = (delay: number) => {
        return new Promise(res => setTimeout(res, delay));
    }

    const autoPrint = async () => {
        if (redirectRequest?.printReceipt) {
            const copies = parseInt(redirectRequest?.printReceipt);
            for (let i = 0; i < copies; i++) {
                checkLocalThermalPrinter();
                await delay(3000);
            }
        }
    }

    useEffect(() => {
        if (printParse) autoPrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printParse]);

    const printEncode = () => {
        let encoder = new EscPosEncoder();
        let data = encoder.initialize().align('center').encode();
        try {
            printParse?.forEach(async (item: any) => {
                let block = new Uint8Array([]);
                const encodeSignature = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let img = new Image();
                        img.src = 'data:image/png;base64,' + item.value;
                        block = encoder.newline().image(img, 480, 160, 'atkinson').align('left').size('small').line('Signature').size('normal').underline(true).line(''.padStart(48)).underline(false).align('center').newline().encode();
                    }
                }
                const encodeOrderDetails = () => {
                    block = encoder.bold(true).line(item.label).bold(false).encode();
                    item.value.forEach((line: any) => {
                        let cost = line.quantity + " x " + line.unitPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        let price = (line.quantity * line.unitPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        let unit = encoder.align('left').bold(true).line(line.itemName).bold(false).text(cost).text(price.toString().padStart(48 - cost.length - 1)).align('center').newline().encode();
                        block = new Uint8Array([...block, ...unit]);
                    });
                }
                const encodeItemReference = () => {
                    if (typeof item.value === 'object' && item.value) {
                        let value = item.value.map((line: any) => { return line.itemReferenceNumber }).join(", ");
                        if (value.replace(", ", "") !== "") {
                            if ((item.label + value).length > 47) {
                                block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                            } else {
                                block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                            }
                        }
                    }
                }
                const encodeCryptogram = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.bold(true).text(item.label).bold(false).text("APPROVED".padStart(48 - item.label.length)).newline().align('right').line(item.value).align('center').newline().encode();
                    }
                }
                const encodeTransactionDateTime = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.bold(true).text(item.label).bold(false).text(moment.utc(item.value).local().format('MM-DD-YYYY h:mm a').padStart(48 - item.label.length)).newline().encode();
                    }
                }
                const encodeDepartmentAddress = () => {
                    if (typeof item.value === 'object' && item.value) {
                        let value = `${item.value.addressLine1} ${item.value.addressLine2}, ${item.value.city} ${item.value.state} ${item.value.zipCode}`
                        if ((item.label + value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodePinVerified = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.line("PIN VERIFIED").newline().bold(true).size('small').line("I agree to pay the above total amount").line("according to the card issuer agreement").size('normal').bold(false).newline().line("THANK YOU").newline().encode();
                    }
                }
                const encodeAmount = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let value = item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodeFee = () => {
                    if (!item.value.orderTransaction?.orderSummary?.convenienceFeeIsClientAbsorbed) {
                        let value = item.value.convenienceFee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value?.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodeDefault = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let value = item.value.toString();
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value.trim()).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.trim().padStart(48 - item.label.length)).newline().encode();
                        }
                    } else {
                        if (!item.value) {
                            if (item.label.length > 65) {
                                let chunks = item.label.match(/\S.{1,62}\S(?= |$)/g);
                                block = encoder.newline().encode();
                                chunks.forEach((chunk: any) => {
                                    let unit = encoder.align('left').size('small').line(chunk.trim()).size('normal').align('center').encode();
                                    block = new Uint8Array([...block, ...unit]);
                                });
                            }
                        }
                    }
                }
                if (_.isEmpty(item) || item.height) { block = encoder.newline().encode(); }
                if (item.text) { block = encoder.line(item.text).encode(); }
                if (item.tag) {
                    switch (item.tag) {
                        case 'Signature':
                            encodeSignature();
                            break;
                        case 'Order Details':
                            encodeOrderDetails();
                            break;
                        case 'Item Reference':
                            encodeItemReference();
                            break;
                        case 'Cryptogram':
                            encodeCryptogram();
                            break;
                        case 'Transaction Date/Time':
                            encodeTransactionDateTime();
                            break;
                        case 'Department Address':
                            encodeDepartmentAddress();
                            break;
                        case 'Pin Verified':
                            encodePinVerified();
                            break;
                        case 'Subtotal':
                        case 'Total Amount':
                            encodeAmount();
                            break;
                        case 'Convenience Fee':
                            encodeFee();
                            break;
                        default:
                            encodeDefault();
                            break;
                    }
                } else {
                    if (item.label) {
                        switch (item.label) {
                            case 'Signature':
                                encodeSignature();
                                break;
                            case 'Order Details':
                                encodeOrderDetails();
                                break;
                            case 'Item Reference':
                                encodeItemReference();
                                break;
                            case 'Cryptogram':
                                encodeCryptogram();
                                break;
                            case 'Transaction Date/Time':
                                encodeTransactionDateTime();
                                break;
                            case 'Department Address':
                                encodeDepartmentAddress();
                                break;
                            case 'Pin Verified':
                                encodePinVerified();
                                break;
                            case 'Subtotal':
                            case 'Total Amount':
                                encodeAmount();
                                break;
                            case 'Convenience Fee':
                                encodeFee();
                                break;
                            default:
                                encodeDefault();
                                break;
                        }
                    }
                }
                if (item.src) {
                    let img = new Image();
                    img.crossOrigin = "Anonymous";
                    img.src = item.src;
                    await img.decode().then(() => { block = encoder.newline().image(img, 320, 320, 'atkinson').newline().encode(); }).catch(() => { });
                }
                data = new Uint8Array([...data, ...block]);
            })
            let feed = encoder.newline().newline().newline().newline().newline().newline().cut().encode();
            data = new Uint8Array([...data, ...feed]);
        } catch (e) {
            dispatch(sendErrorToastAction("MSB Local USB Thermal Printer: Unable to parse receipt..."));
        }
        return data;
    }

    const endpoint = (device: any) => {
        let endpoints = device.configuration.interfaces[0].alternates[0].endpoints;
        for (let e in endpoints) {
            if (endpoints[e].direction === 'out') return endpoints[e].endpointNumber;
        }
    }

    const localPrint = async (device: any) => {
        await device.open()
            .then(async () => { await device.selectConfiguration(1); })
            .then(async () => { await device.claimInterface(device.configuration.interfaces[0].interfaceNumber); })
            .then(async () => { await device.transferOut(endpoint(device), printEncode()).catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not printing...")); }) })
            .catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not configurable...")); });
    }

    const checkLocalThermalPrinter = async () => {
        await navigator.usb.getDevices()
            .then(async devices => { devices.forEach(device => { if (device.vendorId === 4070 && device.productId === 33054) localPrint(device); }); })
    }

    const handleLocalThermalPrinter = async () => {
        await navigator.usb.getDevices()
            .then(async devices => {
                if (devices.length > 0) {
                    devices.forEach(device => { if (device.vendorId === 4070 && device.productId === 33054) localPrint(device); });
                } else {
                    await navigator.usb.requestDevice({ filters: [{ vendorId: 4070, productId: 33054 }] })
                        .then(selectedDevice => { localPrint(selectedDevice); })
                        .catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not found...")); })
                }
            })
    }

    //==========================================================================================================================================

  return (
    <>
        <EmailModal />
        <div className="receipt-details" ref={elementRef}>
            <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
        </div>
        <div className="mb-4 d-print-none" />
        <ButtonToolbar className="d-print-none justify-content-center mt-4">
            <Button variant="outline-secondary" onClick={() => window.print()} style={{ marginRight: "10px" }}>Print Receipt</Button>
            <Button variant="outline-secondary" onClick={() => setShowEmailModal(true)} style={{ marginRight: "10px" }}>Email Receipt</Button>
            {navigator.usb && redirectRequest?.printReceipt ? <Button variant="outline-info" onClick={handleLocalThermalPrinter}>Print to MSB Thermal Printer</Button> : <></>}
        </ButtonToolbar>
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
    return {
        processedOrder: state.orderManagement.processedOrder,
        isStCharles: state.orderManagement.isStCharles,
        isPike: state.orderManagement.isPike,
        statusUpdate: state.orderManagement.statusUpdate,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        webApplication: state.paymentChannelWebApplication.webApplication,
        content: state.cms.content,
        emailIsSending: state.emails.isFetching,
        emailActionResult: state.emails.actionResult,
        redirectRequest: state.redirectIntegration.redirectRequest,
    };
};

export default connect(mapStateToProps)(ReceiptPage);

