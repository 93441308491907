import { Row, Col, Modal, Form, Button, Spinner, OverlayTrigger, Popover, Table as SecondaryTable } from "react-bootstrap"
import { useEffect, useState } from "react";
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import Table from '../../../../../../components/Table';
import FormHeader from "../../../../../../components/layout/FormHeader";
import CurrencyInput from '../../../../../../components/currency/CurrencyInput';
import { CurrencyFormatter } from "../../../../../../components/Formatters";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCheck, faInfoCircle, faTimes, faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { updateOrderDetailsAction, updateShoppingCart } from "../../../../../../redux/actions/orderManagement";
import { sendSuccessToastAction } from "../../../../../../redux/actions/toast";
import { ItemStatusType, Order } from "../../../../../../models/Order";
import _ from "lodash";
import React from "react";

interface IForsythSearchResults {
    typeLabel: string,
    accountYearLabel: string,
    accountNumberLabel: string,
    ticketNumberFirst7Label: string,
    ticketNumberLast4Label: string,
    monthsTaxedLabel: string,
    acreLabel: string,
    landValueLabel: string,
    improvementValueLabel: string,
    principalDueLabel: string,
    penaltyDueLabel: string,
    interestDueLabel: string,
    parcelIdLabel: string,
    nameLabel: string,
    addressLabel: string,
    modelLabel: string,
    vehicleTypeLabel: string,
    totalAmountDueLabel: string,
    classLabel: string,
    valueLabel: string,
    statusLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    order?: any,
    shoppingCart?: any,
    isFetching: boolean,
    itemSearchDetails?:any
}

export const TYPE_LABEL = 'Type';
export const ACCOUNT_YEAR_LABEL = 'Account Year';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const PARCEL_ID_LABEL = 'Parcel ID'
export const NAME_LABEL = 'Name';
export const ADDRESS_LABEL = 'Address';
export const MODEL_LABEL = 'Model';
export const CLASS_LABEL = 'Class';
export const VALUE_LABEL = 'Value';
export const TOTAL_AMOUNT_DUE_LABEL = 'Total Amount Due';
export const STATUS_LABEL = 'Status';
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'There are no available online payment options on this parcel at this time';
export const OVERLAY_BACKGROUND = '#fef1b5';
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const TICKET_NUMBER_FIRST_7 = 'Ticket Number First7';
export const TICKET_NUMBER_LAST_4 = 'Ticket Number Last4';
export const MONTHS_TAXED_LABEL = 'Months Taxed';
export const ACRE_LABEL = 'Acre';
export const LAND_VALUE_LABEL = 'Land Value';
export const IMPROVEMENT_VALUE_LABEL = 'Improvement Value';
export const PRINCIPAL_DUE_LABEL = 'Principal Due';
export const PENALTY_DUE_LABEL = 'Penalty Due';
export const INTEREST_DUE_LABEL = 'Interest Due';
export const VEHICLE_TYPE_LABEL = 'Vehicle Type'

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const DateFormatter = (date: any, row: any) => {
    if (date) {
        const dateString = moment(date).format('MM/DD/YYYY');
        return (
            <span>{dateString}</span>
        );
    }
}

const MathewsSearchResults = ({ typeLabel, accountYearLabel, accountNumberLabel, ticketNumberFirst7Label, ticketNumberLast4Label, monthsTaxedLabel, acreLabel, landValueLabel, improvementValueLabel, principalDueLabel, penaltyDueLabel, 
    interestDueLabel, parcelIdLabel, nameLabel, addressLabel, vehicleTypeLabel,modelLabel, classLabel,
    totalAmountDueLabel, statusLabel, valueLabel, paymentOptionsLabel, durationOfPendingHours, observesWeekends, itemSearchDetails,
    overlayText, overlayBackground, overlayTextColor, overlayIconColor, margin, padding, headerBackgroundColor, headerPadding, itemDetails, shoppingCart, order, isFetching }: IForsythSearchResults) => {

    if (!typeLabel) { typeLabel = TYPE_LABEL }
    if (!accountYearLabel) { accountYearLabel = ACCOUNT_YEAR_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!parcelIdLabel) { parcelIdLabel = PARCEL_ID_LABEL }
    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!modelLabel) { modelLabel = MODEL_LABEL }
    if (!classLabel) { classLabel = CLASS_LABEL }
    if (!valueLabel) { valueLabel = VALUE_LABEL }
    if (!vehicleTypeLabel) { vehicleTypeLabel = VEHICLE_TYPE_LABEL }
    if (!totalAmountDueLabel) { totalAmountDueLabel = TOTAL_AMOUNT_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!ticketNumberFirst7Label) { ticketNumberFirst7Label = TICKET_NUMBER_FIRST_7 }
    if (!ticketNumberLast4Label) { ticketNumberLast4Label = TICKET_NUMBER_LAST_4 }
    if (!monthsTaxedLabel) { monthsTaxedLabel = MONTHS_TAXED_LABEL }
    if (!acreLabel) { acreLabel = ACRE_LABEL }
    if (!landValueLabel) { landValueLabel = LAND_VALUE_LABEL }
    if (!improvementValueLabel) { improvementValueLabel = IMPROVEMENT_VALUE_LABEL }
    if (!principalDueLabel) { principalDueLabel = PRINCIPAL_DUE_LABEL }
    if (!penaltyDueLabel) { penaltyDueLabel = PENALTY_DUE_LABEL }
    if (!interestDueLabel) { interestDueLabel = INTEREST_DUE_LABEL }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const [amount, setAmount] = useState<any>();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState<any>({ option: 'full' });
    const [installmentSelectedOption, setInstallmentSelectedOption] = useState<any>({ option: 'full' });
    const [installmentAmount, setInstallmentAmount] = useState<any>();
    const [cartErrorMessage, setCartErrorMessage] = useState<string>('');

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const totalDueFormatter = (cell:any,row:any) => {
        const totalDue = row?.metaData?.Installments ? _.sumBy(row?.metaData?.Installments, 'TotalDue') : cell;
        return (<span>{formatter.format(totalDue)}</span>)
    }

    const paymentOptionsFormatter = (cell: any, row: any) => {
        const status = row?.itemPaymentStatus != ItemStatusType.Pending && row?.itemPaymentStatus != ItemStatusType.Paid;
        const getOnHoldYears = _.map(_.filter(row?.metaData?.MultipleRecords, { 'onHold': true }), 'TaxYear');
        const delinquentHolds = _.uniq(getOnHoldYears).some(year => row?.metaData?.TaxYear >= year);
        const isOnHold = row?.metaData?.onHold || delinquentHolds;
        const isViewable = status && !isOnHold
        if (isViewable) {
            return (
                <span>
                    <a
                        onClick={() => {
                            setSelectedRow(row);
                            setCartErrorMessage('');
                            setSelectedOption({ option: 'full' });
                            setInstallmentSelectedOption({ option: 'full' })
                            setInstallmentAmount(null);
                            setAmount(null);
                            setShowModal(true)
                        }}
                    >
                        {paymentOptionsLabel}
                    </a>
                </span>
            )
        }
    }


    const onHoldOverlay = () => {
        return (
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                <Popover id="popover-positioned-top">
                    <Popover.Content>
                        Please contact the Treasurer's office for an account balance.
                    </Popover.Content>
                </Popover>
            )}>
                <FontAwesomeIcon icon={faInfoCircle} className="btn-icon" style={{ color: "#f5222d", cursor: 'pointer' }} />
            </OverlayTrigger>
        )
    }

    const statusTypeConverter = (prop: any, row: any) => {
        if (itemDetails) {
            if (prop == ItemStatusType.Pending || prop == ItemStatusType.Paid || prop == ItemStatusType[1] || prop == ItemStatusType[3]) {
                const statusType = typeof (prop);
                const value = statusType === 'number' ? ItemStatusType[prop] : prop;
                return value
            }
            else {
                const getOnHoldYears = _.map(_.filter(row?.metaData?.MultipleRecords, { 'onHold': true }), 'TaxYear');
                const delinquentHolds = _.uniq(getOnHoldYears).some(year => row?.metaData?.TaxYear >= year);
                const isOnHold = row?.metaData?.onHold || delinquentHolds;
                return isOnHold ? 'On Hold' : 'Unpaid'
            }
        }
    }

    const statusFormatter = (cell: any, row: any) => {
        const statusValue = statusTypeConverter(cell, row);
        let color: string = "#F5222D";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                {statusValue === 'On Hold' ?
                    onHoldOverlay()
                    :
                    <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />
                }
                {statusValue}
            </span>
        )
    }

    const initialColumns = [{
        dataField: 'metaData.TaxTypeDescription',
        text: typeLabel,
        staticKey: 'type',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.TaxYear',
        text: accountYearLabel,
        sort: true,
        staticKey: 'accountYear',
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'metaData.AccountNumber',
        text: accountNumberLabel,
        staticKey: 'accountNumber',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.TicketNumberFirst7',
        text: ticketNumberFirst7Label,
        staticKey: 'ticketNumberFirst7',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.TicketNumberLast4',
        text: ticketNumberLast4Label,
        staticKey: 'ticketNumberLast4',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.MapNumberGPIN',
        text: parcelIdLabel,
        staticKey: 'parcelId',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.CombinedNames',
        text: nameLabel,
        staticKey: 'name',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.FullAddress',
        text: addressLabel,
        staticKey: 'address',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.VehModel',
        text: modelLabel,
        staticKey: 'model',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    },{
        dataField: 'metaData.VehType',
        text: vehicleTypeLabel,
        staticKey: 'vehicleType',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.Class',
        text: classLabel,
        staticKey: 'class',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.VehValue',
        text: valueLabel,
        staticKey: 'value',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        sort: true,
    }, {
        dataField: 'metaData.TotalDue',
        text: totalAmountDueLabel,
        staticKey: 'totalDue',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: totalDueFormatter,
        sort: true,
    }, {
        dataField: 'itemPaymentStatus',
        text: statusLabel,
        staticKey: 'status',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
        formatter: statusFormatter,
    }, {
        dataField: 'metaData.ReferenceNumber',
        text: paymentOptionsLabel,
        staticKey: 'paymentOptions',
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: paymentOptionsFormatter
    }];

    const [columns, setColumns] = useState<any>(initialColumns);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [installments, setInstallments] = useState<any>([]);

    const mappingDetails = [
        { heading: accountNumberLabel, value: selectedRow?.metaData?.AccountNumber, type: 'default' },
        { heading: nameLabel, value: selectedRow?.metaData?.CombinedNames, type: 'RE' },
        { heading: ticketNumberFirst7Label, value: selectedRow?.metaData?.TicketNumberFirst7, type: 'default' },
        { heading: ticketNumberLast4Label, value: selectedRow?.metaData?.TicketNumberLast4, type: 'PP&BL' },
        { heading: addressLabel, value: selectedRow?.metaData?.FullAddress, type: 'RE' },
        { heading: modelLabel, value: selectedRow?.metaData?.VehModel, type: 'none' },
        { heading: vehicleTypeLabel, value: selectedRow?.metaData?.VehType, type: 'none' },
        { heading: classLabel, value: selectedRow?.metaData?.Class, type: 'none' },
        { heading: valueLabel, value: selectedRow?.metaData?.VehValue, type: 'none' },
        { heading: monthsTaxedLabel, value: selectedRow?.metaData?.MonthsTaxed, type: 'none' },
        { heading: parcelIdLabel, value: selectedRow?.metaData?.MapNumberGPIN, type: 'RE' },
        { heading: acreLabel, value: selectedRow?.metaData?.Acre, type: 'RE' },
        { heading: landValueLabel, value: CurrencyFormatter(selectedRow?.metaData?.LandValue), type: 'RE' },
        { heading: improvementValueLabel, value: CurrencyFormatter(selectedRow?.metaData?.ImprvmntValue), type: 'RE' },
        { heading: principalDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.PrincipalDue), type: 'PP&BL' },
        { heading: penaltyDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.PenaltyDue), type: 'PP&BL' },
        { heading: interestDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.InterestDue), type: 'PP&BL' },
        { heading: totalAmountDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.TotalDue), type: 'PP&BL' },
    ] as any;

    const handleRadioSelect = (e: any) => {
        setCartErrorMessage('');
        setSelectedOption({ option: e.target.id });
        setAmount(null)
    }

    const partialAmountChange = (value: any) => {
        if (value > selectedRow?.metaData?.TotalDue) {
            setCartErrorMessage('Amounts cannot be greater than total due')
            setAmount(null)
            return
        }
        setCartErrorMessage('')
        setAmount(value)
    }

    const handleInstallmentRadioSelect = (e: any) => {
        setCartErrorMessage('');
        setInstallmentSelectedOption({ option: e.target.id });
        setInstallmentAmount(0)
    }

    const installmentPartialAmountChange = (value: any, installment: any) => {
        if (value > installment?.TotalDue) {
            setCartErrorMessage('Amounts cannot be greater than total due')
            setInstallmentAmount(null)
            return
        }
        setCartErrorMessage('')
        setInstallmentAmount(value)
    }

    const processAndRemoveMatches = (array: any) => {
        const toRemove = new Set();
        const processed = [] as any;
        for (const obj of array) {
            if (obj.metaData?.TaxType == 'RE') {
                const installmentDetails = [
                    { Msbid: obj?.metaData?.Msbid, selected: false, AccountNumber: obj?.metaData?.AccountNumber, Acre: obj?.metaData?.Acre, Class: obj?.metaData?.Class, CombinedNames: obj?.metaData?.CombinedNames, DepartmentNumber: obj?.metaData?.DepartmentNumber, Frequency: obj?.metaData?.Frequency, FullAddress: obj?.metaData?.FullAddress, ImprvmntValue: obj?.metaData?.ImprvmntValue, InterestDue: obj?.metaData?.InterestDue, LandValue: obj?.metaData?.LandValue, MapNumberGPIN: obj?.metaData?.MapNumberGPIN, PenaltyDue: obj?.metaData?.PenaltyDue, PrincipalDue: obj?.metaData?.PrincipalDue, ReferenceNumber: obj?.metaData?.ReferenceNumber, TaxType: obj?.metaData?.TaxType, TaxTypeDescription: obj?.metaData?.TaxTypeDescription, TaxYear: obj?.metaData?.TaxYear, TicketNumberFirst7: obj?.metaData?.TicketNumberFirst7, TicketNumberLast4: obj?.metaData?.TicketNumberLast4, TotalDue: obj?.metaData?.TotalDue, VehModel: obj?.metaData?.VehModel, VehValue: obj?.metaData?.VehValue, VehVinNumber: obj?.metaData?.VehVinNumber, ZipCode2: obj?.metaData?.ZipCode2 }
                ]
                const matchedObjects = [];
                for (const details of array) {
                    if (details !== obj &&
                        details?.metaData?.TaxType == 'RE' &&
                        details?.metaData?.AccountNumber == obj?.metaData?.AccountNumber &&
                        details?.metaData?.TaxYear == obj?.metaData?.TaxYear) {
                        if (details !== obj && !processed.some((p: any) => p?.msbId === details?.msbId)) {
                            toRemove.add(details?.msbId);
                            matchedObjects.push({ ...details?.metaData, selected: false });
                        }
                    }
                }
                if (matchedObjects.length > 0) {
                    const newObj = _.cloneDeep(obj);
                    newObj.metaData.Installments = [...installmentDetails, ...matchedObjects];
                    newObj.metaData.MatchedObject = matchedObjects;
                    processed.push(newObj);
                } else {
                    const newObject = _.cloneDeep(obj);
                    newObject.metaData.OwnLeague = true;
                    processed.push(newObject)
                }
            } else {
                processed.push(obj);
            }
        }
        return processed.filter((obj: any) => !toRemove.has(obj?.msbId))
    }

    useEffect(() => {
        if (itemDetails) {
            const getTaxTypes = _.uniq(itemDetails.map((details: any) => { return details?.metaData?.TaxType }));
            const allProperties = ['type', 'accountYear', 'accountNumber', 'ticketNumberFirst7', 'ticketNumberLast4', 'name', 'address', 'totalDue', 'parcelId', 'model', 'vehicleType', 'class', 'value', 'status', 'paymentOptions'];
            const functionalProperties = ['status', 'paymentOptions']
            const columnsForPersonalProperty = ['type', 'accountYear', 'accountNumber', 'ticketNumberFirst7', 'ticketNumberLast4', 'totalDue', ...functionalProperties];
            const columnsForRealEstate = ['type', 'accountYear', 'accountNumber', 'ticketNumberFirst7', 'parcelId', 'name', 'address', 'totalDue', ...functionalProperties];
            const columnsForBusinessLicense = ['type', 'accountYear', 'accountNumber', 'ticketNumberFirst7', 'ticketNumberLast4', 'totalDue', ...functionalProperties];
            const columnsToDisplay = getTaxTypes.includes('BL') ? columnsForBusinessLicense : getTaxTypes.includes('RE') ? columnsForRealEstate : getTaxTypes.includes('PP') ? columnsForPersonalProperty : allProperties;
            setColumns(initialColumns.filter((obj: any) => columnsToDisplay.includes(obj.staticKey)));
            const defaultSorted = _.orderBy(itemDetails, ['metaData.AccountNumber', 'metaData.TaxYear', 'metaData.TicketNumberFirst7', parseInt('metaData.TicketNumberLast4')], ['asc', 'asc', 'asc', 'asc'])
            const modifiedRealEstateDataset = processAndRemoveMatches(defaultSorted);
            const filterByKey = itemSearchDetails?.searchBy == 'MapNumberGPIN' || itemSearchDetails?.searchBy == 'AccountNumber';
            const filteredItems = filterByKey ? modifiedRealEstateDataset.filter((item: any) => {
                let itemValue = item?.metaData[itemSearchDetails?.searchBy]
                return itemValue === itemSearchDetails?.referenceNumber
            }) : modifiedRealEstateDataset;
            setData(filteredItems)
        }
    }, [itemDetails]);


    useEffect(() => {
        if (selectedRow) {
            setInstallments(selectedRow?.metaData?.Installments);
        }
    }, [selectedRow]);

    const delinquents = selectedRow?.metaData?.MultipleRecords;
    const checkDelinquentRules = delinquents?.filter((item: any) => item?.TaxYear <= selectedRow?.metaData?.TaxYear && item?.TaxType == selectedRow?.metaData?.TaxType);
    const delinquentItems = _.orderBy(checkDelinquentRules, [ 'AccountNumber', 'TaxYear', 'TicketNumberFirst7', (item) => parseInt(item.TicketNumberLast4)], ['asc', 'asc', 'asc', 'asc'])
    const isDelinquent = selectedRow?.metaData?.TaxType == 'RE' ? _.uniq(_.map(delinquentItems,'TaxYear'))?.length > 1 : delinquentItems?.length > 1;

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        remote={false}
                        columns={columns}
                        sizePerPage={5}
                    />
                </>
            );
        }
    };

    const inCart = shoppingCart?.some((cart: any) => cart?.id === selectedRow?.metaData?.ReferenceNumber);

    const handleInstallment = (index: any) => {
        const updatedInstallments = installments.map((installment: any, i: any) => {
            if (i === index) {
                return { ...installment, selected: !installment.selected, isPartial: (i == 0 || installments[index - 1].TotalDue == 0) && !installment.selected }
            } else if (i > index) {
                return {
                    ...installment,
                    selected: false,
                };
            } else {
                const showPartial = installments[index - 1].selected && installments[index].selected;
                if (!showPartial) { setInstallmentSelectedOption({ option: 'full' }); setInstallmentAmount(null) }
                return { ...installment, isPartial: showPartial }
            }
        })
        setInstallments(updatedInstallments)
    }

    const handleCheckboxDisable = (index: any) => {
        let isDisabled = true;
        switch (index) {
            case 0:
                isDisabled = installments[index].TotalDue === 0;
                break;
            case 1: case 2: case 3:
                isDisabled = installments[index].TotalDue === 0 ||
                    (!(installments[index - 1].TotalDue === 0 && !installments[index - 1].selected) && (installments[index - 1].TotalDue != 0 && !installments[index - 1].selected));
                break;
            default:
                isDisabled = true;
                break;
        }
        return isDisabled
    }

    const handleSubmit = (e: any, activeDiv?: any) => {
        e.preventDefault();

        const uniqueFutureDelinquentIds =
            activeDiv == 'Delinquents' ? _.differenceBy(delinquents, delinquentItems, 'Msbid').map((obj: any) => obj?.Msbid) :
                activeDiv == 'Installments' ? _.differenceBy(delinquents, installments?.filter((obj: any) => obj.selected), 'Msbid').map((obj: any) => obj?.Msbid) :
                    _.differenceBy(delinquents, [selectedRow?.metaData], 'Msbid').map((obj: any) => obj?.Msbid);

        const delinquentsObject = delinquentItems?.map((item: any, index: any, arr: any) => ({
            type: item?.TaxType, accountYear: item?.TaxYear, accountNumber: item?.AccountNumber,
            parcelId: item?.MapNumberGPIN, name: item?.CombinedNames, address: item?.FullAddress,
            model: item?.VehModel, class: item?.Class, value: item?.VehValue, totalAmountDue: item?.TotalDue,
            paymentAmount: item?.TotalDue, id: item?.ReferenceNumber, isDeletable: index === arr.length - 1,
            ticketNumberFirst7: item?.TicketNumberFirst7, ticketNumberLast4: item?.TicketNumberLast4, delinquent: true
        }))

        const delinquentOrderLinesObject = delinquentItems?.map((item: any, index: any, arr: any) => ({
            accountNumber: item?.AccountNumber, itemReferenceNumber: item?.ReferenceNumber, quantity: 1, unitPrice: item?.TotalDue,
            itemMetadata: {
                uiReferenceId: item?.ReferenceNumber, statusUpdate: true, itemMsbid: item?.Msbid, observesWeekends: observesWeekends,
                durationOfPendingHours: durationOfPendingHours, departmentNumber: item?.DepartmentNumber,
                accountNumber: item?.AccountNumber, ticketNumberFirst7: item?.TicketNumberFirst7,
                ticketNumberLast4: item?.TicketNumberLast4, vinNumber: item?.VehVinNumber,
                futureDelinQuents: index === arr.length - 1 ? uniqueFutureDelinquentIds : []
            }
        }))

        const installmentsShoppingCartObject = installments?.filter((obj: any) => obj.selected).map((item: any, index: any, arr: any) => ({
            type: item?.TaxType, accountYear: item?.TaxYear, accountNumber: item?.AccountNumber, parcelId: item?.MapNumberGPIN,
            name: item?.CombinedNames, address: item?.FullAddress, model: item?.VehModel, class: item?.Class, value: item?.VehValue,
            totalAmountDue: item?.TotalDue, paymentAmount: (item?.isPartial && installmentAmount != null && installmentAmount != 0) ? _.toNumber(installmentAmount) : Number(item?.TotalDue),
            id: item?.ReferenceNumber, isDeletable: index === arr.length - 1,
            ticketNumberFirst7: item?.TicketNumberFirst7, ticketNumberLast4: item?.TicketNumberLast4
        }))

        const installmentsOrderLinesObject = installments?.filter((obj: any) => obj.selected).map((item: any, index: any, arr: any) => ({
            accountNumber: item?.AccountNumber, itemReferenceNumber: item?.ReferenceNumber, quantity: 1,
            unitPrice: (item?.isPartial && installmentAmount != null && installmentAmount != 0) ? _.toNumber(installmentAmount) : Number(item?.TotalDue),
            itemMetadata: {
                uiReferenceId: item?.ReferenceNumber, statusUpdate: true, itemMsbid: item?.Msbid,
                observesWeekends: observesWeekends, durationOfPendingHours: durationOfPendingHours,
                departmentNumber: item?.DepartmentNumber, accountNumber: item?.AccountNumber,
                ticketNumberFirst7: item?.TicketNumberFirst7, ticketNumberLast4: item?.TicketNumberLast4,
                vinNumber: item?.VehVinNumber, futureDelinQuents: index === arr.length - 1 ? uniqueFutureDelinquentIds : []
            },
        }))

        const defaultShoppingCartObject = [{
            type: selectedRow?.metaData?.TaxType, accountYear: selectedRow?.metaData?.TaxYear, accountNumber: selectedRow?.metaData?.AccountNumber,
            parcelId: selectedRow?.metaData?.MapNumberGPIN, name: selectedRow?.metaData?.CombinedNames, address: selectedRow?.metaData?.FullAddress,
            model: selectedRow?.metaData?.VehModel, class: selectedRow?.metaData?.Class, value: selectedRow?.metaData?.VehValue,
            totalAmountDue: selectedRow?.metaData?.TotalDue, paymentAmount: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.metaData?.TotalDue),
            id: selectedRow?.metaData?.ReferenceNumber, isDeletable: true,
            ticketNumberFirst7: selectedRow?.metaData?.TicketNumberFirst7, ticketNumberLast4: selectedRow?.metaData?.TicketNumberLast4
        }];
        const defaultOrderLinesObject = [{
            accountNumber: selectedRow?.metaData?.AccountNumber, itemReferenceNumber: selectedRow?.metaData?.ReferenceNumber,
            quantity: 1, unitPrice: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.metaData?.TotalDue),
            itemMetadata: {
                uiReferenceId: selectedRow?.metaData?.ReferenceNumber,
                statusUpdate: true, itemMsbid: selectedRow?.msbId, observesWeekends: observesWeekends,
                durationOfPendingHours: durationOfPendingHours, departmentNumber: selectedRow?.metaData?.DepartmentNumber,
                accountNumber: selectedRow?.metaData?.AccountNumber, ticketNumberFirst7: selectedRow?.metaData?.TicketNumberFirst7,
                ticketNumberLast4: selectedRow?.metaData?.TicketNumberLast4, vinNumber: selectedRow?.metaData?.VehVinNumber,
                futureDelinQuents: uniqueFutureDelinquentIds
            },
        }];

        const shoppingCartObject = activeDiv == 'Delinquents' ? delinquentsObject : activeDiv == 'Installments' ? installmentsShoppingCartObject : defaultShoppingCartObject;
        const OrderLinesObject = activeDiv == 'Delinquents' ? delinquentOrderLinesObject : activeDiv == 'Installments' ? installmentsOrderLinesObject : defaultOrderLinesObject;

        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiReferenceId === selected?.itemMetadata?.uiReferenceId)).concat(OrderLinesObject);
            const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
            order.methodName = "Sale";
            order.orderLines = updatedOrderLines;
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
        }
        else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = OrderLinesObject;
            dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(sendSuccessToastAction('Added to cart'))
        setShowModal(false);
    }

    const checkTaxTypeForModal = (dataTaxType: any, selectedArray: any) => {
        const hasSymbol = selectedArray?.type.includes('&');
        if (hasSymbol) {
            const isMatch = selectedArray?.type?.includes(dataTaxType);
            return isMatch
        } else {
            const isMatch = dataTaxType === selectedArray?.type
            return isMatch
        }
    }

    const activeDiv =
        !isDelinquent && selectedRow?.metaData?.Installments && selectedRow?.metaData?.TaxType == 'RE' ? 'Installments'
            : isDelinquent ? 'Delinquents' : 'Default';

    return (
        <div style={{ margin: margin, padding: padding }}>
            <Row><Col>{renderTable()}</Col></Row>
            <Modal show={showModal} size={'lg'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Item Details" description={"Below are the selected item details"} />
                    <Form.Group controlId="printTerminal" >
                        <div className="mathewsDisplayResults">
                            {Object.entries(mappingDetails).map(
                                ([index, selectedArray]: any) => {
                                    const taxTypeMatch = (checkTaxTypeForModal(selectedRow?.metaData?.TaxType, selectedArray)) || selectedArray?.type == 'default' || (selectedRow?.metaData?.OwnLeague && selectedArray?.heading == 'Ticket Number Last4')
                                    if (taxTypeMatch) {
                                        return (
                                            <div key={index} className="neric_details_row">
                                                <h3>{selectedArray.heading}:</h3>
                                                <span>{selectedArray.value}</span>
                                            </div>
                                        )
                                    }
                                }
                            )}
                        </div>
                    </Form.Group>
                    <hr />
                    {!inCart ?
                        <>
                            {!isDelinquent && selectedRow?.metaData?.Installments && selectedRow?.metaData?.TaxType == 'RE' &&
                                <>
                                    <FormHeader title="Installments" description={"Please select the following installments"} />
                                    <div>
                                        {installments?.map((installment: any, index: any) => (
                                            <>
                                                <div className='neric_container'>
                                                    <span className='neric_installment_subheading' style={{ marginRight: '10px' }}>{`Installment ${index + 1}`}</span>
                                                    <label key={index} className='neric_label'>
                                                        <input
                                                            type='checkbox'
                                                            className='neric_input'
                                                            id={`installment-${index}`}
                                                            onChange={() => handleInstallment(index)}
                                                            disabled={handleCheckboxDisable(index)}
                                                            checked={installment.selected}
                                                        />
                                                        {`Principal: ${formatter.format(installment?.PrincipalDue)} Penalty: ${formatter.format(installment?.PenaltyDue)} Interest: ${formatter.format(installment?.InterestDue)} Total Due: ${formatter.format(installment?.TotalDue)}`}
                                                    </label>
                                                </div>
                                                {installment?.isPartial &&
                                                    <div>
                                                        <div className='stCharles_cart'>
                                                            <div className='box'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='paymentOption'
                                                                        className='neric_input'
                                                                        id='full'
                                                                        onChange={handleInstallmentRadioSelect}
                                                                        checked={installmentSelectedOption?.option === 'full'}
                                                                    />
                                                                    Total Due
                                                                </label>
                                                                <span className='box_span'>{formatter.format(Number(installment.TotalDue))}</span>
                                                            </div>
                                                            <div className='box'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='paymentOption'
                                                                        className='neric_input'
                                                                        id='partial'
                                                                        onChange={(e) => setInstallmentSelectedOption({ option: `${e.target.id}` })}
                                                                        checked={installmentSelectedOption?.option === 'partial'}
                                                                    />Payment Amount
                                                                </label>
                                                                <Form.Group controlId="amount" className='box_span'>
                                                                    <CurrencyInput
                                                                        required
                                                                        className="form-control"
                                                                        id="summaryAmount"
                                                                        name="summaryAmount"
                                                                        placeholder="$ 0.00"
                                                                        maxLength={10}
                                                                        decimalsLimit={2}
                                                                        prefix="$ "
                                                                        disabled={installmentSelectedOption?.option != 'partial'}
                                                                        value={installmentAmount}
                                                                        onValueChange={(value: any) => installmentPartialAmountChange(value, installment)}
                                                                    />
                                                                </Form.Group>
                                                                {cartErrorMessage && installmentSelectedOption?.option === 'partial' &&
                                                                    <div role="alert" id="paymentErrorBox" style={{ color: 'red' }}>
                                                                        <FontAwesomeIcon icon={faTimesCircle} color='red' /> {cartErrorMessage}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </>
                            }
                            {isDelinquent &&
                                <div className='stCharles_delinquent'>
                                    <div className='centered_span' style={{ justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                                        <span style={{ color: 'black' }}><strong>Delinquents</strong></span>
                                    </div>
                                    <SecondaryTable responsive bordered={true} className="table-payment-method">
                                        <thead>
                                            <tr>
                                                <th>{'Type'}</th>
                                                <th>{'Account Year'}</th>
                                                <th>{'Account Number'}</th>
                                                <th>{'Ticket Number First 7'}</th>
                                                <th>{'Ticket Number Last 4'}</th>
                                                {selectedRow?.metaData?.TaxType == 'RE' && <th>{'Parcel ID'}</th>}
                                                <th>{'Total Due'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {delinquentItems?.map((info: any) => (
                                                <tr>
                                                    <td>{info?.TaxTypeDescription}</td>
                                                    <td>{info?.TaxYear}</td>
                                                    <td>{info?.AccountNumber}</td>
                                                    <td>{info?.TicketNumberFirst7}</td>
                                                    <td>{info?.TicketNumberLast4}</td>
                                                    {info?.TaxType == 'RE' && <td>{info?.MapNumberGPIN}</td>}
                                                    <td>{CurrencyFormatter(info?.TotalDue)}</td>
                                                </tr>
                                            ))}
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {selectedRow?.metaData?.TaxType == 'RE' && <td></td>}
                                                <td>Total:</td>
                                                <td>{CurrencyFormatter(_.sumBy(delinquentItems, (info: any) => (info?.TotalDue)))}</td>
                                            </tr>
                                        </tbody>
                                    </SecondaryTable>
                                </div>
                            }
                            { !selectedRow?.metaData?.Installments && !isDelinquent &&
                                <>
                                    <FormHeader title="Payment Options" description={"Please select the following payment options"} />
                                    <Form.Group controlId="paymentOptions">
                                        <div className='stCharles_cart'>
                                            <div className='box'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='paymentOption'
                                                        className='neric_input'
                                                        id='full'
                                                        onChange={handleRadioSelect}
                                                        checked={selectedOption?.option === 'full'}
                                                    />
                                                    Total Due
                                                </label>
                                                <span className='box_span'>{formatter.format(Number(selectedRow?.metaData?.TotalDue))}</span>
                                            </div>
                                            <br />
                                            <div className='box'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='paymentOption'
                                                        className='neric_input'
                                                        id='partial'
                                                        onChange={(e) => setSelectedOption({ option: `${e.target.id}` })}
                                                        checked={selectedOption?.option === 'partial'}
                                                    />Payment Amount
                                                </label>
                                                <Form.Group controlId="amount" className='box_span'>
                                                    <CurrencyInput
                                                        required
                                                        className="form-control"
                                                        id="summaryAmount"
                                                        name="summaryAmount"
                                                        placeholder="$ 0.00"
                                                        maxLength={10}
                                                        decimalsLimit={2}
                                                        prefix="$ "
                                                        disabled={selectedOption?.option != 'partial'}
                                                        value={amount}
                                                        onValueChange={(value: any) => partialAmountChange(value)}
                                                    />
                                                </Form.Group>
                                                {cartErrorMessage && selectedOption?.option === 'partial' &&
                                                    <div role="alert" id="paymentErrorBox" style={{ color: 'red' }}>
                                                        <FontAwesomeIcon icon={faTimesCircle} color='red' /> {cartErrorMessage}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            }
                        </>
                        :
                        <div className="stCharles-accordion accordion">
                            <div className='card'>
                                <div className='agreement_toggle_header card-header' style={{ justifyContent: 'center' }}>
                                    <FontAwesomeIcon color='green' icon={faCartPlus} />Added to cart
                                </div>
                            </div>
                        </div>
                    }
                    <Form.Group className="form-footer">
                        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button type="submit"
                            disabled={
                                inCart ||
                                selectedOption?.option == 'partial' && (amount == undefined || amount == 0 || amount > selectedRow?.metaData?.TotalDue) ||
                                selectedRow?.metaData?.TotalDue == 0 ||
                                installments?.length > 0 && !(installments?.some((installment: any) => installment?.selected)) && !isDelinquent ||
                                installmentSelectedOption?.option == 'partial' && (installmentAmount == undefined || installmentAmount == 0)
                            } onClick={(e) => handleSubmit(e, activeDiv)}
                        >
                            {`Add to cart`}
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
        itemSearchDetails: state.orderManagement.itemSearchDetails
    };
};

export default connect(mapStateToProps)(MathewsSearchResults);