import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Table, Modal, Button, InputGroup, Spinner } from "react-bootstrap"
import FormHeaderConfirmation from "../../../../../../components/FormHeaderConfirmation";
import { StateDropdown } from "../../../../../../components/StateDropdown";
import icoWarning from "../../../../../../assets/img/icons/ico-warning-outline.svg";
import icoError from "../../../../../../assets/img/icons/ico-error-outline.svg";

import RequiredIcon from "../../../../../../components/RequiredIcon";
import InputMask from "react-input-mask";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";
import {
    getUserPaymentMethods, addCreditCard, addBankAccount, deleteCreditCard, deleteBankAccount,
    ADD_CREDIT_CARD_REQUEST, ADD_CREDIT_CARD_SUCCESS, ADD_CREDIT_CARD_FAILURE,
    ADD_BANK_ACCOUNT_REQUEST, ADD_BANK_ACCOUNT_SUCCESS, ADD_BANK_ACCOUNT_FAILURE,
    DELETE_CREDIT_CARD_REQUEST, DELETE_CREDIT_CARD_SUCCESS, DELETE_CREDIT_CARD_FAILURE,
    DELETE_BANK_ACCOUNT_REQUEST, DELETE_BANK_ACCOUNT_SUCCESS, DELETE_BANK_ACCOUNT_FAILURE,
    resetBankName, retrieveBankName
} from "../../../../../../redux/actions/account";

import { sendSuccessToastAction, sendErrorToastAction } from "../../../../../../redux/actions/toast";
import { CreditCard, User, eCheck } from "../../../../../../models/User";
import { PaymentCardTypeEnum, PaymentType } from '../../../../../../models/Order'
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BusinessTypeEnum, Client, Department, MerchantProcessor as Merchant, PaymentChannel, SubMerchantAcceptedCardTypeEnum } from "../../../../../../models/Client";
import { PaymentCardType } from "../../../../../../utils/binLookup";
import { isInvalidCardExpiration } from "../../../../../../utils/globalCalculations";

interface IUserPaymentMethods {
    paymentTypeLabel: string,
    cardTypeLabel: string,
    accountNumberLabel: string,
    expirationDateLabel: string,
    actionsLabel: string,
    cardholderNameLabel: string,
    cardholderNamePlaceholder: string,
    cardholderNameRequired: string,
    modalCardTypeLabel: string,
    cardTypeRequired: string,
    cardNumberLabel: string,
    cardNumberRequired: string,
    modalExpirationDateLabel: string,
    expirationDateRequired: string,
    cvvLabel: string,
    cvvRequired: string,
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    zipCodeLabel: string,
    zipCodeRequired: string,
    nameOnAccountLabel: string,
    nameOnAccountPlaceholder: string,
    nameOnAccountRequired: string,
    bankAccountNumberLabel: string,
    bankAccountNumberRequired: string,
    confirmBankAccountNumberLabel: string,
    confirmBankAccountNumberRequired: string,
    bankRoutingNumberLabel: string,
    bankRoutingNumberRequired: string,
    financialInstitutionLabel: string,
    headerBackgroundColor: string,
    user: User,
    userDetails: User,
    actionResult: IActionResult,
    paymentMethodUniqueId: string,
    isSaving: boolean,
    scheduledPaymentsLoading: boolean,
    userPaymentsLoading: boolean,
    scheduledItems: any,
    bankName: string,
    client: Client,
    department: Department,
    paymentChannel?: PaymentChannel
}

export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const CARD_TYPE_LABEL = 'Card Type';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const EXPIRATION_DATE_LABEL = 'Expiration Date';
export const ACTIONS_LABEL = 'Actions';

export const ADDRESS_LINE_1_LABEL = 'Address line 1';
export const ADDRESS_LINE_1_PLACEHOLDER = 'Enter address line 1';
export const ADDRESS_LINE_2_LABEL = 'Address line 2';
export const ADDRESS_LINE_2_PLACEHOLDER = 'Enter address line 2';
export const CITY_LABEL = 'City';
export const CITY_PLACEHOLDER = 'Enter city';
export const STATE_LABEL = 'State';
export const ZIP_CODE_LABEL = 'Zip code';
export const ZIP_CODE_REQUIRED = 'Zip code required';

export const CARDHOLDER_NAME_LABEL = 'Cardholder name';
export const CARDHOLDER_NAME_PLACEHOLDER = 'Enter Cardholder name';
export const CARDHOLDER_NAME_REQUIRED = 'Please enter the Cardholder’s name.';
export const MODAL_CARD_TYPE_LABEL = 'Card Type'
export const CARD_TYPE_REQUIRED = 'Please select the Card type.';
export const CARD_NUMBER_LABEL = 'Card number';
export const CARD_NUMBER_REQUIRED = 'Please enter a valid 12-16 digit Card number.';
export const MODAL_EXPIRATION_DATE_LABEL = 'Expiration date';
export const EXPIRATION_DATE_REQUIRED = 'Please enter the Expiration date.';
export const CVV_LABEL = 'CVV';
export const CVV_REQUIRED = 'Please enter the CVV number.';


export const NAME_ON_ACCOUNT_LABEL = 'Name on account';
export const NAME_ON_ACCOUNT_PLACEHOLDER = 'Enter Name on account';
export const NAME_ON_ACCOUNT_REQUIRED = 'Please enter the Name on account.';
export const BANK_ACCOUNT_NUMBER_LABEL = 'Bank account number';
export const BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const CONFIRM_BANK_ACCOUNT_NUMBER_LABEL = 'Confirm bank account number';
export const CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED = 'Please enter the Bank account number.';
export const BANK_ROUTING_NUMBER_LABEL = 'Bank routing number';
export const BANK_ROUTING_NUMBER_REQUIRED = 'Please enter a valid Bank routing number.';
export const FINANCIAL_INSTITUTION_LABEL = 'Financial Institution';

export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const UserPaymentMethods = ({
    paymentTypeLabel, cardTypeLabel, accountNumberLabel, expirationDateLabel, actionsLabel, headerBackgroundColor,
    cardholderNameLabel, cardholderNamePlaceholder, cardholderNameRequired, modalCardTypeLabel, cardTypeRequired, cardNumberLabel, cardNumberRequired,
    modalExpirationDateLabel, expirationDateRequired, cvvLabel, cvvRequired,
    addressLine1Label, addressLine1Placeholder, addressLine2Label, addressLine2Placeholder, cityLabel, cityPlaceholder, stateLabel, zipCodeLabel, zipCodeRequired,
    nameOnAccountLabel, nameOnAccountPlaceholder, nameOnAccountRequired, bankAccountNumberLabel, bankAccountNumberRequired, confirmBankAccountNumberLabel,
    confirmBankAccountNumberRequired, bankRoutingNumberLabel, bankRoutingNumberRequired, financialInstitutionLabel,
    user, userDetails, actionResult, paymentMethodUniqueId, isSaving, scheduledItems, bankName, scheduledPaymentsLoading, userPaymentsLoading, client, department, paymentChannel
}
    : IUserPaymentMethods) => {

    const actionToken = "UserPaymentMethods";

    const [paymentType, setPaymentType] = useState<string>("");
    const [creditCardModal, showCreditCardModal] = useState<boolean>(false);
    const [eCheckModal, showEcheckModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteValidation, setDeleteValidation] = useState<boolean>(false);
    const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
    const [confirmBankAccountNumber, setConfirmBankAccountNumber] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [showCVV, setShowCVV] = useState<boolean>(false);
    const [showCardNumber, setShowCardNumber] = useState<boolean>(false);

    const [cardExpirationInvalid, setCardExpirationInvalid] = useState<boolean>(false);
    const [cardExpirationMessage, setCardExpirationMessage] = useState<string>('');
    

    const dispatch = useDispatch();
    const [data, setData] = useState<any>([]);
    const [creditCardValidation, setcreditCardValidation] = useState<boolean>(false);
    const [eCheckValidation, setECheckValidation] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [cvv, setCvv] = useState<any>('');

    const [cardType, setCardType] = useState("");
    const [cardTypeEnum, setCardTypeEnum] = useState<PaymentCardTypeEnum>(0);
    const [cardTypeInvalid, setCardTypeInvalid] = useState(false);
    const cardNumber = useRef<HTMLInputElement | null>(null);

    if (!paymentTypeLabel) paymentTypeLabel = PAYMENT_TYPE_LABEL;
    if (!cardTypeLabel) cardTypeLabel = CARD_TYPE_LABEL;
    if (!accountNumberLabel) accountNumberLabel = ACCOUNT_NUMBER_LABEL;
    if (!expirationDateLabel) expirationDateLabel = EXPIRATION_DATE_LABEL;
    if (!actionsLabel) actionsLabel = ACTIONS_LABEL;
    if (!cardholderNameLabel) cardholderNameLabel = CARDHOLDER_NAME_LABEL;
    if (!cardholderNamePlaceholder) cardholderNamePlaceholder = CARDHOLDER_NAME_PLACEHOLDER;
    if (!cardholderNameRequired) cardholderNameRequired = CARDHOLDER_NAME_REQUIRED;
    if (!modalCardTypeLabel) modalCardTypeLabel = MODAL_CARD_TYPE_LABEL;
    if (!cardTypeRequired) { cardTypeRequired = CARD_TYPE_REQUIRED; }
    if (!cardNumberLabel) cardNumberLabel = CARD_NUMBER_LABEL;
    if (!cardNumberRequired) cardNumberRequired = CARD_NUMBER_REQUIRED;
    if (!modalExpirationDateLabel) modalExpirationDateLabel = MODAL_EXPIRATION_DATE_LABEL;
    if (!expirationDateRequired) expirationDateRequired = EXPIRATION_DATE_REQUIRED;
    if (!cvvLabel) cvvLabel = CVV_LABEL;
    if (!cvvRequired) cvvRequired = CVV_REQUIRED;
    if (!nameOnAccountLabel) nameOnAccountLabel = NAME_ON_ACCOUNT_LABEL;
    if (!nameOnAccountPlaceholder) nameOnAccountPlaceholder = NAME_ON_ACCOUNT_PLACEHOLDER;
    if (!nameOnAccountRequired) nameOnAccountRequired = NAME_ON_ACCOUNT_REQUIRED;
    if (!bankAccountNumberLabel) bankAccountNumberLabel = BANK_ACCOUNT_NUMBER_LABEL;
    if (!bankAccountNumberRequired) bankAccountNumberRequired = BANK_ACCOUNT_NUMBER_REQUIRED;
    if (!confirmBankAccountNumberLabel) confirmBankAccountNumberLabel = CONFIRM_BANK_ACCOUNT_NUMBER_LABEL;
    if (!confirmBankAccountNumberRequired) confirmBankAccountNumberRequired = CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED;
    if (!bankRoutingNumberLabel) bankRoutingNumberLabel = BANK_ROUTING_NUMBER_LABEL;
    if (!bankRoutingNumberRequired) bankRoutingNumberRequired = BANK_ROUTING_NUMBER_REQUIRED;
    if (!financialInstitutionLabel) financialInstitutionLabel = FINANCIAL_INSTITUTION_LABEL;
    if (!addressLine1Label) addressLine1Label = ADDRESS_LINE_1_LABEL;
    if (!addressLine1Placeholder) addressLine1Placeholder = ADDRESS_LINE_1_PLACEHOLDER;
    if (!addressLine2Label) addressLine2Label = ADDRESS_LINE_2_LABEL;
    if (!addressLine2Placeholder) addressLine2Placeholder = ADDRESS_LINE_2_PLACEHOLDER;
    if (!cityLabel) cityLabel = CITY_LABEL;
    if (!cityPlaceholder) cityPlaceholder = CITY_PLACEHOLDER;
    if (!stateLabel) stateLabel = STATE_LABEL;
    if (!zipCodeLabel) zipCodeLabel = ZIP_CODE_LABEL;
    if (!zipCodeRequired) zipCodeRequired = ZIP_CODE_REQUIRED;

    useEffect(() => {
        if (user) {
            dispatch(getUserPaymentMethods(user.msbId!, actionToken));
        }
    }, [paymentMethodUniqueId, user]);

    useEffect(() => {
        if (userDetails) {
            setData(userDetails);
        }
    }, [userDetails]);


    useEffect(() => {
        if (actionResult && actionResult.type === ADD_CREDIT_CARD_REQUEST) {
            if (actionResult.result === ADD_CREDIT_CARD_SUCCESS) {
                dispatch(sendSuccessToastAction("Payment method has been successfully added."));
                showCreditCardModal(false);
            } else if (actionResult.result === ADD_CREDIT_CARD_FAILURE) {
                dispatch(sendErrorToastAction("Payment method could not be saved."));
            }
        }
        if (actionResult && actionResult.type === ADD_BANK_ACCOUNT_REQUEST) {
            if (actionResult.result === ADD_BANK_ACCOUNT_SUCCESS) {
                dispatch(sendSuccessToastAction("Payment method has been successfully added."));
                showEcheckModal(false);
            } else if (actionResult.result === ADD_BANK_ACCOUNT_FAILURE) {
                dispatch(sendErrorToastAction("Payment method could not be saved."));
            }
        }
        if (actionResult && actionResult.type === DELETE_CREDIT_CARD_REQUEST) {
            if (actionResult.result === DELETE_CREDIT_CARD_SUCCESS) {
                dispatch(sendSuccessToastAction("Payment method has been successfully deleted."));
            } else if (actionResult.result === DELETE_CREDIT_CARD_FAILURE) {
                dispatch(sendErrorToastAction("Payment method could not be deleted."));
            }
        }
        if (actionResult && actionResult.type === DELETE_BANK_ACCOUNT_REQUEST) {
            if (actionResult.result === DELETE_BANK_ACCOUNT_SUCCESS) {
                dispatch(sendSuccessToastAction("Payment method has been successfully deleted."));
            } else if (actionResult.result === DELETE_BANK_ACCOUNT_FAILURE) {
                dispatch(sendErrorToastAction("Payment method could not be deleted."));
            }
        }
    }, [actionResult]);


    const handleCreditCardSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false && !cardTypeInvalid) {
            let creditCardDetails = new CreditCard();
            const expirationDate = form.elements.expirationDate.value;
            creditCardDetails.cardNumber = form.elements.cardNumber.value;
            creditCardDetails.nameOnCard = form.elements.nameOnCard.value;
            creditCardDetails.expirationMonth = expirationDate.split("/")[0];
            creditCardDetails.expirationYear = expirationDate.split("/")[1];
            creditCardDetails.cVV = form.elements.cvv.value;
            creditCardDetails.internalCardType = cardTypeEnum;
            creditCardDetails.addressLine1 = form.elements.addressLine1.value;
            creditCardDetails.addressLine2 = form.elements.addressLine2.value;
            creditCardDetails.city = form.elements.city.value;
            creditCardDetails.state = form.elements.state.value;
            creditCardDetails.zipCode = form.elements.zipCode.value;
            dispatch(addCreditCard(user.msbId!, creditCardDetails));
        }
        setcreditCardValidation(true);
    };

    const handleECheckSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false && form.elements.bankRoutingNumber.value.length == 9 && bankName) {
            let eCheckDetails = new eCheck();
            eCheckDetails.bankAccountType = 'Checking';
            eCheckDetails.accountHolderName = form.elements.nameOnAccount.value;
            eCheckDetails.accountNumber = form.elements.confirmBankAccountNumber.value;
            eCheckDetails.routingNumber = form.elements.bankRoutingNumber.value;
            eCheckDetails.addressLine1 = form.elements.addressLine1.value;
            eCheckDetails.addressLine2 = form.elements.addressLine2.value;
            eCheckDetails.city = form.elements.city.value;
            eCheckDetails.state = form.elements.state.value;
            eCheckDetails.zipCode = form.elements.zipCode.value;
            dispatch(addBankAccount(user.msbId!, eCheckDetails));
        }
        setECheckValidation(true);
    };

    const actionFormatter = (accountNumber: string | undefined, paymentMethod: string) => {
        if (accountNumber) {
            return <a onClick={() => { setPaymentType(paymentMethod); deletePaymentMethodValidation(paymentMethod) }}>Delete</a>
        }
        if (!accountNumber && paymentMethod === 'eCheck') {
            return <a onClick={() => { setECheckValidation(false); showEcheckModal(true); setBankAccountNumber('') }}>Add</a>
        }
        if (!accountNumber && paymentMethod === 'Credit Card') {
            return <a onClick={() => { setcreditCardValidation(false); showCreditCardModal(true) }}>Add</a>
        }
    }

    const lastFourECheck = (account: string) => {
        if (account) {
            return <span>{account.slice(account.length - 4)}</span>
        }
    }

    const deletePaymentMethodValidation = (paymentMethod: string) => {
        if (paymentMethod === 'Credit Card') {
            const getScheduledTokens = scheduledItems.map((itemDetails: any) => {
                const payloadInfo = itemDetails?.paymentWorkItem?.apiWorkInformation?.payload
                if (payloadInfo.paymentType == PaymentType[1] && itemDetails?.paymentScheduleStatus === 'Pending') {
                    return payloadInfo.token.tokenID
                }
            }).filter(Boolean)
            const deleteCard = !getScheduledTokens.some((item: any) => data.cards[0].token.includes(item))
            if (deleteCard) {
                setDeleteModal(true)
            } else { setDeleteValidation(true) }
        }
        if (paymentMethod === 'eCheck') {
            const getScheduledAccounts = scheduledItems.map((itemDetails: any) => {
                const payloadInfo = itemDetails?.paymentWorkItem?.apiWorkInformation?.payload
                if (payloadInfo.paymentType == PaymentType[2] && itemDetails?.paymentScheduleStatus === 'Pending') {
                    return payloadInfo?.eCheckAccountNumber
                }
            }).filter(Boolean)
            const deleteAccount = !getScheduledAccounts.some((item: any) => data.bankAccounts[0].accountNumber.includes(item))
            if (deleteAccount) {
                setDeleteModal(true)
            } else { setDeleteValidation(true) }
        }
    }

    const deletePaymentMethod = () => {
        if (paymentType === 'Credit Card') {
            dispatch(deleteCreditCard(data.cards && data.cards[0].msbId))
        }
        if (paymentType === 'eCheck') {
            dispatch(deleteBankAccount(data.bankAccounts && data.bankAccounts[0].msbId))
        }
        setDeleteModal(false);
    }

    const renderButtons = (formId: string) => {
        return (
            <>
                <Form.Group controlId={`save${formId}`} className="form-footer mb-0 mt-5">
                    <Button form={formId} type="submit" disabled={isSaving || error}>
                        Save
                    </Button>
                    <Button form={formId} style={{ marginLeft: 'auto' }} variant="link" disabled={isSaving}
                        onClick={() => { formId === 'creditCardPaymentMethod' ? showCreditCardModal(false) : showEcheckModal(false) }}>
                        Cancel
                    </Button>
                </Form.Group>
            </>
        )
    }

    const handleOnlyNumeric = (e: any) => {
        if (e.key === " " || isNaN(e.key)) {
            e.preventDefault();
        }
    };

    const matchAccountNumber = (e: any) => {
        const confirmAccount = e.target.value;
        setConfirmBankAccountNumber(confirmAccount);
        if (confirmAccount !== bankAccountNumber) {
            setError(true);
        } else { setError(false) }
    }

    useEffect(() => {
        if (!eCheckModal) {
            setTouched(false);
            setConfirmBankAccountNumber('');
            dispatch(resetBankName());
        }
    }, [eCheckModal]);

    useEffect(() => {
        if (!creditCardModal) {
            setCardType("")
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
    }, [creditCardModal]);

    const handleRoutingNumberOnChange = (e: any) => {
        const val = e.target.value;
        if (val.length === 9) {
            dispatch(retrieveBankName(val));
        } else {
            if (bankName) dispatch(resetBankName());
        }
        setTouched(true);
    }

    const doBinLookup = async (val: any) => {
        if (val.length >= 8) {
            PaymentCardType(val).then(result => {
                switch (result) {
                    case PaymentCardTypeEnum.AmericanExpressCredit:
                        if (cardTypeEnum != PaymentCardTypeEnum.AmericanExpressCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressCredit);
                            setCardType("American Express");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("American Express is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.AmericanExpressDebit:
                        if (cardTypeEnum != PaymentCardTypeEnum.AmericanExpressDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.AmericanExpressDebit);
                            setCardType("American Express - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("American Express - Debit is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.DiscoverCredit:
                        if (cardTypeEnum != PaymentCardTypeEnum.DiscoverCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.DiscoverCredit);
                            setCardType("Discover");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Discover is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.DiscoverDebit:
                        if (cardTypeEnum != PaymentCardTypeEnum.DiscoverDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.DiscoverDebit);
                            setCardType("Discover - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Discover - Debit is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.MastercardCredit:
                        if (cardTypeEnum != PaymentCardTypeEnum.MastercardCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.MastercardCredit);
                            setCardType("Mastercard");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Mastercard is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.MastercardDebit:
                        if (cardTypeEnum != PaymentCardTypeEnum.MastercardDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.MastercardDebit);
                            setCardType("Mastercard - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Mastercard - Debit is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.VisaCredit:
                        if (cardTypeEnum != PaymentCardTypeEnum.VisaCredit) {
                            setCardTypeEnum(PaymentCardTypeEnum.VisaCredit);
                            setCardType("Visa");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Visa is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    case PaymentCardTypeEnum.VisaDebit:
                        if (cardTypeEnum != PaymentCardTypeEnum.VisaDebit) {
                            setCardTypeEnum(PaymentCardTypeEnum.VisaDebit);
                            setCardType("Visa - Debit");
                            if (!paymentChannel?.softBlockedAcceptedCards?.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
                                setCardTypeInvalid(false);
                            } else {
                                dispatch(sendErrorToastAction("Visa - Debit is not accepted"));
                                setCardTypeInvalid(true);
                            }
                        }
                        break;
                    default:
                        setCardType("Other");
                        setCardTypeEnum(PaymentCardTypeEnum.Unknown);
                        setCardTypeInvalid(true);
                        break;
                }
            });
        } else {
            setCardType("");
            setCardTypeEnum(PaymentCardTypeEnum.Unknown);
            setCardTypeInvalid(false);
        }
    };

    const handleOnChangeCardNumber = (e: any) => {
        let val = e.target.value;
        if (val.length >= 8) {
            if (!cardType) doBinLookup(val);
        } else {
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
        setCvv("");
    }


    const handleOnPasteCardNumber = (e: any) => {
        let val = e.clipboardData.getData("text");
        if (val.length >= 8) {
            doBinLookup(val);
        } else {
            setCardType("");
            setCardTypeEnum(0);
            setCardTypeInvalid(false);
        }
        setCvv("");
    }

    const handleOnBlurCardName = (e: any) => {
        if (cardNumber.current) {
            let val = cardNumber.current.value;
            if (val.length >= 8) doBinLookup(val);
        }
    }

    const checkCardExpiry = (e: any) => {
        const cardExpiry = e.target.value;
        const checkDateFormat = /^[0-9\/\s]{5}$/
        const isValid = checkDateFormat.test(e.target.value)
        if (isValid) {
            const checkValidation = isInvalidCardExpiration(cardExpiry)
            if (!checkValidation) {
                setCardExpirationInvalid(false)
            } else {
                setCardExpirationInvalid(true)
                setCardExpirationMessage(checkValidation)
            }
        }
    }

    const renderECheck = () => {
        return (
            <>
                <div className="details_title">
                    <h2>Add eCheck Payment Method</h2>
                    <p>Please enter the required information below for this payment method.</p>
                </div>
                <Row>
                    <Form.Group controlId="nameOnAccount">
                        <Form.Label><RequiredIcon />{nameOnAccountLabel}</Form.Label>
                        <Form.Control type="input" placeholder={nameOnAccountPlaceholder} required pattern="^([a-zA-z\s'-])*$" maxLength={100} />
                        <Form.Control.Feedback type="invalid">{nameOnAccountRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="bankAccountNumber">
                            <Form.Label><RequiredIcon />{bankAccountNumberLabel}</Form.Label>
                            <Form.Control type="input" required pattern="^\d*$" maxLength={17} value={bankAccountNumber} onChange={(e) => {setBankAccountNumber(e.target.value);setConfirmBankAccountNumber('')}} onKeyPress={(e: any) => handleOnlyNumeric(e)} className="fs-mask" />
                            <Form.Control.Feedback type="invalid">{bankAccountNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="confirmBankAccountNumber">
                            <Form.Label><RequiredIcon />{confirmBankAccountNumberLabel}</Form.Label>
                            <Form.Control required pattern="^\d*$" maxLength={17} onKeyPress={(e: any) => handleOnlyNumeric(e)} disabled={!bankAccountNumber} value={confirmBankAccountNumber} onChange={(e: any) => matchAccountNumber(e)} className="fs-mask" />
                            <Form.Control.Feedback type="invalid">{confirmBankAccountNumberRequired}</Form.Control.Feedback>
                            {error && <span className="form-custom-error">Account numbers must match</span>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="bankRoutingNumber">
                            <Form.Label><RequiredIcon />{bankRoutingNumberLabel}</Form.Label>
                            <Form.Control type="input" required pattern="^\d*$" minLength={9} maxLength={9} onKeyPress={(e: any) => handleOnlyNumeric(e)} onChange={(e: any) => handleRoutingNumberOnChange(e)} isInvalid={!bankName && touched} className="fs-mask" />
                            <Form.Control.Feedback type="invalid">{bankRoutingNumberRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="financialInstitution">
                            <Form.Label>{financialInstitutionLabel}</Form.Label>
                            <Form.Control type="input" required disabled={true} value={bankName} />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }

    const renderCreditCard = () => {
        return (
            <>
                <div className="details_title">
                    <h2>Add Credit Card Payment Method</h2>
                    <p>Please enter the required information below for this payment method.</p>
                </div>
                <Row>
                    <Col >
                        <Form.Group controlId="nameOnCard">
                            <Form.Label><RequiredIcon />{cardholderNameLabel}</Form.Label>
                            <Form.Control type="input" maxLength={50} placeholder={cardholderNamePlaceholder} required onBlur={handleOnBlurCardName} />
                            <Form.Control.Feedback type="invalid">{cardholderNameRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="cardNumber">
                            <Form.Label><RequiredIcon />{cardNumberLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" onKeyPress={(e: any) => handleOnlyNumeric(e)} onChange={handleOnChangeCardNumber} onPaste={handleOnPasteCardNumber} ref={cardNumber} />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                        <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cardNumberRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="cardType">
                            <Form.Label><RequiredIcon />{modalCardTypeLabel}</Form.Label>
                            <Form.Control type="input" required disabled={isSaving} value={cardType} placeholder={"Displayed upon card number entry"} isInvalid={cardTypeInvalid} readOnly tabIndex={-1}></Form.Control>
                            <Form.Control.Feedback type="invalid">{cardTypeRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="expirationDate">
                            <Form.Label><RequiredIcon />{modalExpirationDateLabel}</Form.Label>
                            <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" mask="99/99" onChange={checkCardExpiry} className={cardExpirationInvalid ? "customValidation" :"form-control"} />
                            <Form.Control.Feedback type="invalid">{expirationDateRequired}.</Form.Control.Feedback>
                        </Form.Group>
                        {cardExpirationInvalid &&
                            <Form.Group controlId="expirationDateMasked">
                                <Form.Control type="input" isInvalid={cardExpirationInvalid} style={{ display: 'none' }} required={cardExpirationInvalid} />
                                <Form.Control.Feedback type="invalid"> {cardExpirationMessage}</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                    <Col>
                        <Form.Group controlId="cvv">
                            <Form.Label><RequiredIcon />{cvvLabel}</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type={showCVV ? "text" : "password"} required 
                                value={cvv} onChange={(e) => setCvv(e.target.value)}
                                minLength={cardTypeEnum == PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum == PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3} maxLength={cardTypeEnum == PaymentCardTypeEnum.AmericanExpressCredit || cardTypeEnum == PaymentCardTypeEnum.AmericanExpressDebit ? 4 : 3}  className="password" />
                                <InputGroup.Append>
                                    <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                        <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">{cvvRequired}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }

    const billingDetails = () => {
        return (
            <>
                <div className="details_title">
                    <h2>Billing Details</h2>
                </div>
                <Row>
                    <Col>
                        <Form.Group controlId="addressLine1">
                            <Form.Label>{addressLine1Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" placeholder={addressLine1Placeholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="addressLine2">
                            <Form.Label>{addressLine2Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" placeholder={addressLine2Placeholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="city">
                            <Form.Label>{cityLabel}</Form.Label>
                            <Form.Control maxLength={28} type="input" placeholder={cityPlaceholder} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="state">
                            <Form.Label>{stateLabel}</Form.Label>
                            <StateDropdown ></StateDropdown>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="zipCode">
                            <Form.Label><RequiredIcon />{zipCodeLabel}</Form.Label>
                            <Form.Control type="input" placeholder="Enter Zip Code" maxLength={5} required />
                            <Form.Control.Feedback type="invalid">{zipCodeRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }


    return (
        <>
            <Modal show={creditCardModal} size='lg' dialogClassName="modal-50w" onHide={() => showCreditCardModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form id='creditCardPaymentMethod' noValidate validated={creditCardValidation} onSubmit={handleCreditCardSubmit}>
                        {renderCreditCard()}
                        <hr />
                        {billingDetails()}
                        {renderButtons('creditCardPaymentMethod')}
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={eCheckModal} size='lg' dialogClassName="modal-50w" onHide={() => showEcheckModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form id='eCheckPaymentMethod' noValidate validated={eCheckValidation} onSubmit={handleECheckSubmit}>
                        {renderECheck()}
                        <hr />
                        {billingDetails()}
                        {renderButtons('eCheckPaymentMethod')}
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={deleteModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete payment method" />
                    <div className="confirmation-body">
                        <p>Are you sure you want to delete this payment method?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => deletePaymentMethod()}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={deleteValidation} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoError} title="Unable to delete payment method" />
                    <div className="confirmation-body">
                        <p>This payment method is associated with scheduled payment. Please delete the scheduled payment first, or assign a new payment method.</p>
                        <Form.Group className="confirmation-footer">
                            <Button onClick={() => setDeleteValidation(false)}>
                                Close
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

            <Row>
                <Col>
                    <PerfectScrollbar>
                        {userPaymentsLoading || scheduledPaymentsLoading ?
                            <div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>
                            :
                            <Table responsive bordered={true} className="table-payment-method">
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{paymentTypeLabel}</th>
                                        <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{cardTypeLabel}</th>
                                        <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{accountNumberLabel}</th>
                                        <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{expirationDateLabel}</th>
                                        <th style={{ backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR) }}>{actionsLabel}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Credit Card</td>
                                        <td>{data.cards && data.cards[0]?.vantivCardType}</td>
                                        <td>{data.cards && data.cards[0]?.lastFourOnCard}</td>
                                        <td>{data.cards && data.cards[0]?.expirationDate}</td>
                                        <td>{actionFormatter(data.cards && data.cards[0]?.lastFourOnCard, 'Credit Card')}</td>
                                    </tr>
                                    <tr>
                                        <td>eCheck</td>
                                        <td></td>
                                        <td>{lastFourECheck(data.bankAccounts && data.bankAccounts[0]?.accountNumber)}</td>
                                        <td></td>
                                        <td>{actionFormatter(data.bankAccounts && data.bankAccounts[0]?.accountNumber, 'eCheck')}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                    </PerfectScrollbar>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.account.isSaving,
        user: state.auth.currentUser,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        userDetails: state.account.user,
        actionResult: state.account.actionResult,
        paymentMethodUniqueId: state.account.paymentMethodUniqueId,
        scheduledItems: state.account.scheduledItems,
        userPaymentsLoading: state.account.userPaymentsLoading,
        scheduledPaymentsLoading: state.account.scheduledPaymentsLoading,
        bankName: state.account.bankName
    };
};

export default connect(mapStateToProps)(UserPaymentMethods);
