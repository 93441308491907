
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";
import CardPlugin from "../paymentChannels/web/components/plugins/CardPlugin";
import HeaderPlugin from "../paymentChannels/web/components/plugins/HeaderPlugin";
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import NavigationLinkPlugin from "../paymentChannels/web/components/plugins/NavigationLinkPlugin";
import ItemSearchPlugin from "../paymentChannels/web/components/plugins/ItemSearchPlugin";
import UserPaymentMethodsPlugin from "../paymentChannels/web/components/plugins/UserPaymentMethodsPlugin";
import UserScheduledPaymentsPlugin from "../paymentChannels/web/components/plugins/UserScheduledPaymentsPlugin";
import PlainTextPlugin from "../paymentChannels/web/components/plugins/PlainTextPlugin";
import NavigationMenuPlugin from "../paymentChannels/web/components/plugins/NavigationMenuPlugin";
import ButtonPlugin from "../paymentChannels/web/components/plugins/ButtonPlugin";
import SpacerPlugin from "../paymentChannels/web/components/plugins/SpacerPlugin";
import CopyrightFooterPlugin from "../paymentChannels/web/components/plugins/FooterCopyrightPlugin";
import PrivacyPolicyTermsConditionFooterPlugin from "../paymentChannels/web/components/plugins/FooterPrivacyPolicyTermsConditions";

export interface IParams {
  id: string
}

export interface IAccountPageProps {
  content: Content,
  webApplication: WebApplication,
  actionResult: IActionResult
}

const AccountPage = ({ webApplication, content, actionResult }: IAccountPageProps) => {
    console.log("AccountPage");
    let actionToken = "AccountPage"
    const dispatch = useDispatch();
    const { id }:IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [webPage, setWebPage] = useState<WebPage>();
    const [redirect, setRedirect] = useState<string>("");

    const setRoute = (path:string, webApplicationName:string) => {
        return path.replace(":id", webApplicationName) + "?" + WebPageEnum.Account;
    }

    let cardPlugin = CardPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let headerPlugin = HeaderPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let navigationLinkPlugin = NavigationLinkPlugin as CellPlugin<unknown, unknown>;
    let itemSearchPlugin = ItemSearchPlugin as CellPlugin<unknown, unknown>;
    let userPaymentMethodsPlugin = UserPaymentMethodsPlugin as CellPlugin<unknown, unknown>;
    let userScheduledPaymentsPlugin = UserScheduledPaymentsPlugin as CellPlugin<unknown, unknown>;
    let navigationMenuPlugin = NavigationMenuPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let copyrightFooterPlugin = CopyrightFooterPlugin as CellPlugin<unknown, unknown>;
    let footerPrivacyPolicyTermsCondition = PrivacyPolicyTermsConditionFooterPlugin as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(imagePlugin);
    cellPlugins.push(headerPlugin);  
    cellPlugins.push(cardPlugin);
    cellPlugins.push(horizontalRulePlugin);
    cellPlugins.push(plainTextPlugin);  
    cellPlugins.push(navigationLinkPlugin);  
    cellPlugins.push(itemSearchPlugin);
    cellPlugins.push(userPaymentMethodsPlugin);  
    cellPlugins.push(userScheduledPaymentsPlugin);
    cellPlugins.push(navigationMenuPlugin);
    cellPlugins.push(buttonPlugin);  
    cellPlugins.push(spacerPlugin);  
    cellPlugins.push(copyrightFooterPlugin);  
    cellPlugins.push(footerPrivacyPolicyTermsCondition);  

    useEffect(() => {
        if (webApplication) {
            let webPage = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.Account)[0];
            if (webPage) { 
                if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                    dispatch(getContentAction(webPage.contentId, actionToken));
                } else {
                    //// TODO - need to hanlde, as there is content for page
                }
                setWebPage(webPage);
            }
        } else {
            ////redirect back to start and load the web application
            setRedirect(setRoute(Routes.WebApplicationStart.path, id));
        }
    }, [webApplication]);

    useEffect(() => {
        if (content && webPage && content.msbId! === webPage.contentId) {
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    setValue(value);
                    break;
                }
            }
        }
    }, [content]);   

    if (redirect !== "") {
        return (<Redirect push to={redirect!} />)
    } else {
        return (
            <>
                <div id="nonPrintable">
                    <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
                </div>
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        webApplication: state.paymentChannelWebApplication.webApplication,
        content: state.cms.content
    };
};

export default connect(mapStateToProps)(AccountPage);

